// artistTexts.js
export const artistTexts = {
//윤태준
'TaejunYun': `
<strong>회한의 무게 Weight of Remorseful, Artist Statement</strong> 

<p>
나의 기억과 연관된 대상은 그 기억을 떠올리는데 중요한 역할을 한다. 이러한 대상을 얼 음속에 얼리는 행위는 기억을 간직하려는 나의 욕망을 보여준다. 동시에 서서히 녹고있는 것은 영원히 간직할 수 없다는걸 알려준다.
이 작업을 처음 시작하게 된 계기는 키우던 개의 죽음이다. 가장 가 까운 대상의 죽음은 나에게 이상한 감정으로 다가왔다. 몇년동안 그죽음을 마주하지 않다 가, 그것을 무덤이라는 대상으로 마주했을때 느꼇던 감정은 새로웠다. 상실감도 아니고 슬 픈것도 아니였다. 다른 대상으로 변형된 그것을 마주했을때, 마치 기억을 다시 떠올리는 것 과 같은 느낌이었다.
나에게 기억이라는 것은 특정한 시점에 존재하는 것은 아니다. 과거의 기억이 특정한 시간 에 머물러 있지 않고 흐르는 시간속에서 함께 움직인다고 생각한다. 기억은 하나의 특정한 시간과 때가 아니라 흐르는 시간속에서 함께 움직인다. 아주 사소한 기억들이 나의 주위에 살아있고 함께 있다. 특별한 사물, 또는 특정한 시간만이 의미 있는 것은 아니다. 잊혀진 것 들과 주위에 있는 것들 또한 나의 한 부분이다. 그것들은 언제나 나와 연관되어 있고 나를 이루는 하나의 개체와도 같다. 사진으로 찍히는 순간 이것들은 나의 새로운 추억과 기억이 된다. 또한 나의 역사를 가지고 있으며 나를 확인시켜준다.

The objects that are related to my memory play an important role in reminding it. The act of freezing represents my desire to preserve my memory. At the same time, ice that is slowly melting shows that it cannot be fully kept.
I started this work when my dog died. It was the first time for me to face the death of a being that was close to me. This gave me a strange emotion. I did not confront the death for several years. When I faced it through the grave, the feeling I had was different. It was not the sense of loss nor sadness. When I saw what was transformed into a different object, I felt like as if I was recalling my memory.
For me, memory does not exist in a specific time. The memory of the past does not stay still in the fixed time, but stays in the continuing time. It moves along the flowing time, not in specific time and moment. Trivial memories live near me. Special objects or specific time are not the only ones that are valuable. The forgotten things and the things near them make a part of me. They are always connected with me, and I am composed of them.
The moment when the objects are photographed by me, they become my new memory. They bear my history and they verify me.
</p>
</br>
<strong>미들턴 Middle Turn </strong>
<p>
<미들턴 Middle Turn> (2020~2021) 작업은 특정한 피사체나 배경 등의 여러 사진적 요소들을 2D, 3D 그래픽 소프트웨어에서 서로 조합하여, 작업의 이미지로 제작했다. 광학적인 촬영으로 만들어낼 수 있는 사진과 3D로 만들어낼 수 있는 소스들을 함께 결합했다. 3D 그래픽 소프트웨어에서 사진은 두께가 있는 단단한 배경이 되거나 물체의 표면으로 둔갑한다. 이후 가상의 사물을 사진과 충돌시키거나 새로운 성질을 부여하는 동시에 사진의 여러 부분들을 조합해 하나의 사진으로 제작한다. 조합된 가상의 장면을 사진적 이미지로 재현함으로서, 실제 존재할 수 없는 형상을 사진으로 만들어낸다. 작업에 등장하는 주요한 대상은 돌과 돌의 형상을 가진 가상의 사물이다. 우리는 손이라는 신체기관을 통해 대상을 지각한다. 손에 꽉차는 단단함과 무게, 거친 표면의 질감을 통해 돌이라는 대상을 머리속으로 그려낸다. 이러한 일련의 과정은 돌이 찍힌 평면의 사진을 볼때도 동일하게 적용된다. <미들턴 Middle turn> (2020~2021)작업에선 돌의 형상을 한 가상의 사물과 실제 돌의 특성이 혼합되거나 대체되는 지각과정을 사진의 형태로 제작한다. 가상과 사진, 사진과 가상이 결합하는 작업의 과정을 통해 사진이 무엇인가를 재현하는 방식에 대해서 이야기하고자 한다.

The Middle Turn (2020-2021) work was produced as an image of the work by combining various photographic elements such as a specific subject and background in 2D and 3D graphic software. Photographs that can be created optically and sources that can be created in 3D are combined together. In 3D graphics software, a photographs becomes a solid background with thickness or turns into the surface of an object. Afterwards, the virtual object is collided with the photo or given a new property against its originality, and at the same time, various parts of the photo are combined to produce a single photo. By reproducing the combined virtual scene as a photographic image, a shape that cannot actually exist is created as a photograph. The main objects that appear in the work are stones and virtual objects in the shape of stones. We perceive objects through the body organ called the hand. Through the hardness and weight that fills the hand and the texture of the rough surface, the object of stone is drawn in the mind. This processes is also applied when looking at a picture of a flat surface on which a stone is taken. In <Middle turn> (2020-2021), the perception process in which a virtual stone-shaped object and the characteristics of an actual stone are mixed or replaced is produced in the form of a photograph. I would like to talk about the way photography reproduces something through the process of combining virtuality and photography, and photography and virtuality.

</p>

<strong>환상계단 Illusion Stair </strong>
<p>
환상계단 작업은 실존하지 않는 대상을, 물성을 가진 오브젝트로 치환시키는 과정에 방점이 있다. 사진 매체를 이용하여 실존하지 않는 대상을 얇은 사진의 레이어위에 올려 놓는다. 물성으로서 사진이미지로 치환하는 과정에서 드러나는 얇은 레이어-사진에 대해 탐구한다. 사진으로 치환되는 대상들은 사진안에서 실존하지 않는 ‘영’이라는 존재들로 표현된다. 또한 북서울의 지역에서 진행한 일련의 퍼포먼스를 통해 추락과 상승하는 삶의 단면을 드러낸다.

In the case of a circular staircase work, there is room for replacing an object which is not existent with an object having physical properties. Use a photographic medium to place a non-existent object on a layer of thin photos. Investigate thin layer-photographs revealed in the process of replacing with photographic images as physical properties. The objects to be replaced with photographs are expressed as 'spirit' beings that do not exist in the photograph. In addition, a series of performances in the area of North Seoul reveals the falling and rising aspects of life.
</p>
<br/>
<strong>상승 Ascent</strong>
<p>
<상승 The Ascent> 작업은 오르려는 현상을 통해 상승이라는 개념을 시각적인 이미지로 제작했다. 도시풍경에서 흔히 볼 수 있는 높은 건축물이 있는 풍경을 상승이라는 요소로 치환하고, 이러한 개념을 상상할 수 있는 신체의 움직임을 이미지로 재현한다. 
먼저 높은 건축물을 인터넷에서 찾아서 수집하거나, 높은 건물을 사진으로 찍는다. 이후 두개의 비트로 이미지를 변환하고 종이에 인쇄한다. 두개의 비트-흑과 백으로 찍혀진 사진을 부분 확대하여 재촬영하고, 원본의 이미지와 혼합하는 과정을 거쳐 하나의 이미지로 만들어낸다. 동시에 높이 오르려는 신체의 이미지를 함께 병치한다. 조각난 신체의 일부들은 높은 건물들의 이미지와 병치되며, 상승을 성취하고자하는 신체적 욕구를 드러낸다. 동시에 희미한 신기루와 같은 이미지들은 상승의 욕구와 정반대의 의미를 제시한다. 이러한 이미지의 조합을 통해,  도시환경에서 상승을 기반으로 삶의 방식을 은유적으로 드러낸다.

The Ascent explores the concept of upward mobility through visual storytelling. The work transforms the familiar urban landscape of towering structures into a symbol of ascent, depicting the human desire to reach higher and strive for more through imaginative imagery.
The process begins with collecting and sourcing images of tall buildings, either from the internet or through photography. These images are then transformed into a 2-bit representation and printed onto paper. Through partial enlargement and re-photography, the 2-bit black and white image is blended with the original to form a singular, dynamic composition. The fragmented pieces of the ascending figure are intertwined with the towering structures, embodying the physical and psychological urge to climb and achieve. Alongside these representations, ethereal and ghost-like imagery presents a counterpoint to the ascent, suggesting the intangible and ephemeral nature of the human experience.
By marrying the technical and the abstract, "The Ascent" weaves a tapestry of visual metaphors that delve into the urban experience and the role of ascension in shaping our lives.
</p>
`,
//김찬규
'ChankyuKim': `

<strong>  시시한 전집 A Dull Complete Works, Artist Statement </strong>
<p>
살다보면 간혹 일상적인 것들은 그것이 익숙하다는 이유로 우리들의 관심사에 들지 못하는 경우를 종종 볼 수 있다. 아니 사실 우리는 보통 우리에게 익숙한 것 들에 대해서는 크게 의식하지 않으며 살아가게 된다. 그리고 삶에 있어서 대개의 크고 작은 문 제들은 우리가 의식하지 않고 살았던 바로 그 일상적인 것들에서 시작 되어지고는 한다.
그들의 이야기의 대개는 별다를 것 없는 시시한 이야기이다. 지나간 어제와 별다를 것 없는 오늘, 그리고 막연한 내일에 대한 이 야기이다. 생각해 보면 우리가 사는 일이 다들 그렇지 않은가 싶다. 별것 아닌 일에 화내거나, 돌이켜 보면 그 자신도 웃음을 금 치 못할 정도로 유치하게 사랑에 빠지는가 하면, 또 아주 작고 하찮은 것들에 감동하거나 슬퍼하면서.....
작업 시시한 전집은 이렇게 익숙하기 때문에 의식하기 힘든, 정말로 하찮고 시시해 보이지만 사실은 그 무엇 보다도 중요한 인생 에 대해서, 다만 살아간다는 것의 의미에 대해서 생각해 보고자 하는 작업이다. 그리고 이 같은 이유로 시시한 사람들의 시시한 이 야기를 하나, 하나 수집하여 그것을 다시 정리하고 결국에는 하나의 전집을 만들고자 하는 것이 이 작업의 지향점이라 할 수 있다.

We don’t often concern about daily things because they are too familiar. Actually we are not really conscious of the things that we are used to. But most of the small or big problems we have in our life start from these daily things that we do not recognize.
They are just stories of yesterday that already passed, today which is not special and vague tomorrow. Thinking this way, our life is similar to this. Getting angry about a petty matter, having a childish infatuation, getting impressed or getting sad about triviality.....
A Dull Complete Works is about our important life that can seem trivial because of its familiarity. I want to think the meaning of the life through this work. The aim is to collect dull stories of ordinary people and arrange them, and make a collective works out of them.
</p>

<strong> 뉴신신사진관 New Sinsin Photo Studio, Artist Statement </strong>
<p>
“뉴신신사진관은 창원조각비엔날레가 열리는 마산지역에서 신신사진관/예식장 에서 영감을 받았고 각자의 해석을 통해 작업 을 하며 신신건물 1층 공간에서 전시를 구성할 예정이다. 신신사진관은 마산에서 60년대 후반, 작은 사진관으로 시작해 지금의 신신사진관/예식장이 있기까지 마산의 역사를 담고 있는 곳이다. 신신의 백낙삼은 자신의 인생을 바로 여기, 신신에 바쳤다 해 도 과언이 아니다. 84세의 고령에도 불구하고 지금도 그는 매일 신신을 청소하고 손님이 오기를 기다린다. 뉴신신사진관은 백낙 삼과 백낙삼의 신신 그리고 마산의 지역에 대해 이해하고 조사하는 과정을 함께 할 예정이다. 네명의 작가들은 신신 안에서 뉴신신이라는 새로운 공간을 만들것이다.(뉴신신사진관)
인간 백낙삼은 전쟁세대로서 적수공권의 시절을 버티면서 자신의 일과 가족을 성공적으로 책임진 사람이다. 그 세대의 사람들 이 모두 다 그렇게 살고, 버티어 왔다고 해서 백낙삼 사장의 인생과 그의 강인함을 절하하거나, 무시할 수 있다고는 생각하지 않 지만 나는 그가 일구어낸 공간 속에서 그는 계속해서 자기 자신을 증명하기 위해 노력하고 있다는 인상을 받았다. 그는 대통령 에게 하사받은 훈장과 함께 각종 보도 자료 등 자신이 만들고 이룩한 모든 업적을 증명해 놓고 있었고, 방문객들에게 그 자신의 대본 화 된 파란만장한 인생 스토리를 계속해서 이야기 해주고 있었다.
그에게서 받게 된 몇 가지 기이한 감정들과 특별한 인상들은 궁극적으로 내게 강함과 남자로서의 삶에 대해 자문하도록 만들었 고, 나는 아직 다 여물지 않은 내 머리로는 쉽게 풀어내지 못 할 인생의 숙제를 작업을 통해서 풀어내 보고자 한다.

NewShinShin Sajinkwan was inspired by the Sinsin Studio/Wedding Hall in Masan area where the biennale is held. The team members will each provide unique interpretations towards this photo studio/wedding hall, and the end results will be shown at the first floor of Shinshin building. Shinshin Photo Studio was, initially, a small photo studio built in the late 1960’s, but developed into a now grand Shinshin Building which holds a photo studio and a wedding hall. The founder of this place is the photographer, Mr. Baek Naksam who has worked here, and has literally witnessed the history of Masan for five decades. Mr. Baek devoted his whole life to Shinshin studio, and even though he is 84 years old, he starts his day by cleaning the studio and waits for the people to come in to take a photo. NewShinShin Sajinkwan will include the study and research on the life of Mr. Baek, his Shinshin Studio, and also on the Masan area. Four artists had teamed up together to make NewShinShin Sajinkwan inside Shinshin Studio, and a new space will be made. (NewShinShinSa- jinkwan)
Mr. Baek Naksam survived many wars with empty hands and naked fists to support his family and his business. I know that all the people who went through that era worked so hard like him, but this is a statement which underestimates his efforts and ordeals that he had to go through. I was under an impression that he was constantly trying to prove himself within the space that he had established and built. He displayed all medals including the presidential one, press scripts that covered himself and his photo studio along with all his other achievements. All these were constantly telling the visitors of his life story that is full of vicissitudes, tumultuous histories and ups and downs. All these evoked very strange feelings inside me along with very special impressions, which eventually led me to ask myself what life is for a real man, and for a strong person, and what the meaning of power and strength is. Hence, with my works, I will do my best to try to answer these questions which I could not solve with my immature brain and logic.
</p>

<strong> 이야기, 이야기 그리고 이야기 Story, Story and Story, Artist Statement </strong>
<p>
이 프로젝트는 미술관 주변지역 커뮤니티의 참여로 완성된다. 미술관에 인접한 풍문여고 학생들의 사적인 기억이 담긴 장소의 이야기를 기반으로 작가는 참여자들 대신 삼청동 일대의 장소들을 방문 하여 사진을 담고, 이를 하루 동안 관객들과 함께 나누어 보고자 한다. 여고 학생들의 이야기를 토대 로 작가의 눈을 통해 드러난 사진 작품들을 미술관 관람객들은 현장에서 자신의 기억과 맞바꾸어 가 지고 갈 수 있다.

This project is based on the memories specifically regarding of the students of poong-moon girl’s high school. After collecting these memories, the artist visits their memorable places and takes pictures of them. These stories are displayed in the museum for a day, and the audience can switch their memories with the ones displayed in the museum.
</p>


`,
//정영돈
'YoungdonJung': `
<strong> 개미 Ants, Artist Statement </strong>
<p>
다양한 인간들이 한 공간 안에서 만들어내는 운율. 고층 아파트 꼭대기에서 볼 수 있는 풍경이다. 높은 곳에서 바라본 인간들은 각자의 차림새와 보폭으로 움직이고 있다. 그런 그들의 움직임을 관찰하다 반복적인 패턴을 갖고 있음을 알게 되었다.
크게는 다양한 군중들의 모임과 흩어짐이 있고, 좀 더 세밀하게는 그 각자의 신체가 내뿜는 집중과 분산의 이미지가 있다. 그 이미지가 드러내는 것은 군중들의 삶과 몸체의 방향성이다. 그러나 물기가 사라지면 어딘가로 뿔뿔이 흩어지고 마는 흙처럼, 그들의 움직임도 하나의 분명한 윤곽으로 잡히지는 않는다.
찍는 순간 눈앞에서 사라져버리는 이미지들은 한 장의 사진 속에서 간신히 손에 쥔 흙덩어리처럼 뭉쳐져 있다. 사진이란 프레임이 사라진다면 이 이미지의 입자들은 금새 다른 곳으로 흩어져버릴 것이다. 그렇다면 저 이미지의 알갱이들은, 또 어느 곳에서 어떠한 이미지의 운율을 만들어 낼 것인가

The rhythm made by all different human beings in the same space. This is the scenery which we can see at the top of a high-rise apartment. Looking from there, people have different styles of walking and outfits. Watching the behaviors, I catch particular repetitive patterns from it.
There is gathering and scattering of diverse crowds. There is also an image of concentration and dispersion from their own bodies in detail. Those images show the people’s lives and the direction of their bodies. Like earth scatters when it gets dry, people’s movements are also hard to form a clear outline.
The images that disappear from our sight as soon as we take them are crumpled like a clod of dirt in our hands. If the frame called a photo disappears, the particles of this image will be scattered in all directions. Where will they make another rhythm of images, and in what sense?
</p>

<strong> 의아한 산책 Wondering Wandering, Artist Statement </strong>

<p>
I lost my way home. Was it because I mistook the way or, because I didn’t know where I was. Although, I have been living in my home town over 10 years, I didn’t know that the geography of here and what was my desire in there. There is only sounds of gun, a bird’s wings, sweet cement powder and feelings of desolate mist. Looking back then, I couldn’t seize anything and just spent the time. However, unlike me everything has been changing as repeating banding and scattering with many events in a long time and it aims external perfect all the time. It suddenly occurs to me that I will live just one of the viewers or I will make my camera be stuck on it.
I have caught peoples, things on my 4x5 view camera with riding a bicycle and camping. I requested to peoples write down their story on the letter and to them draw the city’s shape seen from their eyes. Through wandering on the road with the unknown peoples and observing the surrounded landscapes, I wanted to understand the face of other forms of life and differences enter into a relation with each others in my home town with ambivalence. We trust familiarity of life but sometimes, we are embarrassed about its unfamiliar scenery. I hope those photographic image will bring up us to be filled with the questions about life.
Inner Color, Artist Statement
To participants
Write your name on gold, triangular board. Paint the backside with the colour of your choice. You can create your own colour by mixing black, cyan, magenta, yellow. It is aim to collect 105 pieces and build a dome shaped construction located in front CCA Łaźnia 2. The golden dome will hide in its insides 105 individually chosen colours. I would like to take a picture of every participant. Modified version of “Inner Colour” installation will be presented in the frames of “Miracle on the Lifeless Vistula” on 15th of August.

"Inner colour" is an interdisciplinary project consisting of installation, artistic and documentary interventions and a publication. It was created in collaboration with the 105 participants, whose names play an integral part in the installation. Gdańsk based legend "Dominic’s Fiancee" relating to the symbolism of colours as a reflection of human soul became the inspiration.
</p>

<strong> Inner Color, Artist Statement </strong>
<p>
To participants
Write your name on gold, triangular board. Paint the backside with the colour of your choice. You can create your own colour by mixing black, cyan, magenta, yellow. It is aim to collect 105 pieces and build a dome shaped construction located in front CCA Łaźnia 2. The golden dome will hide in its insides 105 individually chosen colours. I would like to take a picture of every participant. Modified version of “Inner Colour” installation will be presented in the frames of “Miracle on the Lifeless Vistula” on 15th of August.

"Inner colour" is an interdisciplinary project consisting of installation, artistic and documentary interventions and a publication. It was created in collaboration with the 105 participants, whose names play an integral part in the installation. Gdańsk based legend "Dominic’s Fiancee" relating to the symbolism of colours as a reflection of human soul became the inspiration.
</p>
<span>Aleksandra Ksiezopolska</span>
`,
//김형식
'HyungsikKim': `
<strong>Studio Practice, Artist Statement </strong>

<p>
나는 사진에 관한 사진 작업을 한다.
나는 사진이 동시대의 감수성을 가장 잘 대변하는 매체라고 생각한다. 우리는 사진을 마치 언어처럼 사용하기도 하고, 그것을 통해서 세상을 보는 것에 익숙하다. 사진은 아주 친숙한 것이면서, 동시에 매우 낯선 의미의 것이기도 하다. 나의 관심은 사진이 우리 삶에 어떤 영향을 주고 있는지, 그것은 지금 어떤 의미이고 어떻게 변하고 있는지 이다.
사진은 거짓말이다. 진짜처럼 보이지만, 사실 진실과는 상관이 없다. 그것은 선택된 일부분이고, 여러 가지가 중첩되고 반사된 이미지다. 흥미로운 것은 세상이 변하고, 기술이 발전할 수록 이미지의 형태와 매체의 의미가 변한다는 것이다.
나의 작업은 이러한 현상을 관찰하고, 그것에 대해 질문하는 과정이다. 나의 스튜디오는 이미지를 생산하는 공간이자, 일종의 실험실이기도 하다.
</p>

<p>
My photography is on photography.
I think photography is the best medium that represents sensibilities of the contemporary world. We use photography as language, and we see the world through the photography. Photography is a very familiar thing, and at the same time it has a strange meaning. My interest lies on how the photography influences our lives, and what it means now or how it is changing.
Photography is a lie. It looks real, but has nothing to do with the truth. It is a selected piece, or a reflected and overlapped image of various things. It is interesting that the form of the image and the meaning of the media keep changing as time passes and technology develops.
My work is the process of finding and questioning about this phenomenon. My Studio is a space producing image, and a laboratory of this process.
</p>

<strong>Seascape, Artist Statement</strong>
<p>
나는 한국 초기 사진가 민충식의 작업을 통해 사진과 근대성에 대한 연결고리를 생각해 보았고, 동시대에 있어 사진은 어떤 역할을 하고 있는지 고민해 보았다.
작업은 언뜻 보기에 바다 풍경을 기록한 것처럼 보이지만, 그 이면에는 전혀 다른 이야기를 담고 있다. 동시대에 사진 이미지는 보다 복잡하고 추상적인 것이 되어가고 있다.
이전까지의 사진 매체는 기록을 위해 존재해 왔다. 현실을 기록하는 것은 사진가에게 주어진 소명이자, 목적과도 같았다. 동시대의 사진 매체는 단순히 보이는 것을 기록하는 데 그치지 않고, 그 이면에 또 다른 것들을 담기 시작했다. 이제 사진에서 보여지는 이미지는 그 자체로 온전할 수 없게 되었다.

Seascape From the works of Choongsik Min, an early photographer in Korea, I tried to find the connection between modern photography and modernity, also the role of photography in these days.
Historically, the role and responsibility of photography was to record the real world. These days. The images shown in photography cannot be completed by itself.
This work seems to be seascapes, however, there is another story behind the image. Photography is being complicated and abstract.
</p>
<strong>White Object는, Artist Statement </strong>
<p>
White Object는 일상적인 삶과 사물, 그리고 사진 매체와의 관계에 대해 질문하는 작업이다. 이 작업은 북서울시립미술관 공공미술 프로젝트 ‘안녕하세요 2017’의 일환으로 시작되었다. 작가는 미술관 인근 지역민들로부터 삶에서 한때는 소중했지만 이제는 쓸모 없어진 사물들을 기증받아 흰색의 사물로 변형하고, 변형된 사물을 촬영하여 작품으로서 재조명한다. 부분적으로 편집된 이미지는 사진이 지닌 한계성과 그것이 우리의 삶과 시간을 어떻게 표현하는지에 대해 드러낸다.
</p>

`,
//김재연
'JaeyeonKim': `
<strong>4810 Days, Artist Statement </strong>
<p>
땅은 만물의 근원이다. 사람들이 먹는 모든 음식은 땅에서 자라며 이를 ‘농사’라고 한다. 그렇기에 땅은 어머니라고 표현하며 농사는 예전부터 매우 성스럽고 고귀한 것으로 전해졌다.
땅을 고르고 씨앗을 뿌리고 작물이 자라는 이 과정은 사람이 성장하는 과정과 매우 비슷하다. 여린 새싹은 신생아만큼이나 많은 주의를 필요로 하고, 작물이나 사람 모두 어느 정도 자랄 때까지는 많은 관심과 사랑을 필요로 하며, 작물을 수확하고 사람을 결혼시키는 일을 모두 ‘시집보낸다’라고 표현하는 점은 매우 닮아있음을 보여준다. 나는 이런 비유법을 사용해 농사와 사람의 과정을 빗대어 작업을 한다. 그 중에서도 나를 키워주신 엄마의 모습을 통해 내가 지금까지 자라온 과정을 비유적으로 다루었다.
작업은 엄마가 내가 10살 되던 때까지 써주신, 4810일의 ‘육아일기’로 진행한다. 나도 기억나지 않는 나의 유년시절이 적혀있는 이 일기에 있는 것들은 촬영의 자료가 된다.
10.22(月) 아침에 떨어지지 않으려고 하더니 이젠 큰엄마가 맘마먹자 하면 그때부터는 엄마가 필요 없다는 듯이 엄마한테는 관심이 없다. 요즈음은 낮에는 우유를 200ml만 먹고 거의 밥하고 간식을 먹는다. 아주 잘 먹는다.
엄마가 나에게 우유를 먹이듯 난 작물에게 200ml의 물을 준다. 그리고 난 이것을 촬영한다. 매우 간단하고 단순한 이 프로세스는 농사와 육아는 매우 닮아있고 생명을 다루는 매우 소중한 행위임을 말해준다.
나는 대부분의 사람이 엄마, 아빠가 되어 아이를 키우듯 이 땅에서 자라나는 작물들, 농사 또한 많이 소중하다는 것을 말하고 싶었다. 작물이 조금씩 자라는 과정 속에서 엄마의 사랑이 느껴졌으면 좋겠다.

<p>
Earth is the source of everything. All that we eat grows from earth, and we call this 'farming'. We liken the Earth to 'mother' nature, and farming has been a sacred and honorable act long since.
The overall process of leveling the soil, sowing seed, and growing crop is similar to the growth of a young child. Tender sprouts require as much care as infants does; crops and people both need love and attention until they grow up to a certain age, and we use the expression "give away" (in Korean) when we marry our daughters off and also when we harvest the crops. I used the metaphor between farming and rearing of a child as the theme of my project.
In detail, I related the project to my own personal life, focusing on my mother and how she brought me up.
The baby book my mother has kept for 4810 days since my birth is the basic frame of my project. Episodes of my childhood, which I cannot even remember is the subject of my work.
10.22(Mon) While I was unable to unattach her (Jae yeon) every morning when I left for work, she seemed to forget about me whenever her aunt called her for meals. Nowadays, she only drinks 200ml of milk and eats rice meals with snacks in between. She has a good appetite.
Just as my mother fed me 200ml of milk, I water my crops everyday, photographing the whole process. Though watering crops is a simple and ordinary chore, its similarity with feeding a baby reveals equal importance in the sense of taking care of a lifeform.
I had wanted to convey the value of growing crops by using the metaphor of a parent raising a child. I wish that my viewers would also be able to find the love I have tried to capture in my photographs.
</p>

<strong>무제 Untitled, Artist Statement </strong>
<p>
매일 같은 길을 걸으며 볼 수 있는 것들이 많아졌다. 어제는 보이지 않았던 얇은 가지들이 나타났고, 자연스레 그것들을 기록하기 시작했다. 하지만 그 기간은 길게 주어지지 않았다. 12월에서 2월까지, 잎이 지고 새 잎이 나기 전까지 약 3개월 동안만 가능했다. 그 시간동안 내가 보는 것처럼 그들이 보일 수 있는 시간을 찾아갔고 그 시간은 사진의 분위기를 만들어줬다. 걸으며 내가 본 것뿐 아니라 내 머릿속 스쳐지나간 느낌도 풍경과 함께 기록했다. 중간중간 떠오르는 생각들도 모아갔다.
그래서일까? 사진 속 대상들은 전혀 관련이 없어 보이는 것들이다. 그것들은 내 머릿속에서만 온전히 얽혀있으며, 또 의미한다. 브레인스토밍하듯 대상을 찍어나갔고 의미를 찾아갔다. 하나를 촬영하고 나면 다음 이미지가 명확한 단어로 떠오를 때도, 이미지로 그려질 때도 있었다. 때론 좋아하는 길을 걸으며 그 안의 숨은 대상들을 찾기도 했다.

I like taking a walk. One day I find new things in the same place. The next day I see what I couldn't see the day before. After seeing that, I can see more and more. So I take pictures of them.
My work is processed by brainstorming. After I shoot one, I imagine the next one and find similar thing, and then take a picture. So it is hard to find similarity in my pictures. They are entangled and the meaning is only in my head.
I think people are too busy so they don't see many things. I am interested in little things that people overlook. I want to find hidden things in daily life.
</p>

<strong>A Portrait, Artist Statement </strong>

<p>
우연히 한 식물을 찍은 두개의 필름을 겹쳐 스캔하는 일이 있었는데 나에게는 그 이미지가 마치 식물의 포트레이트 같았다. 사람의 포트레이트처럼 식물의 얼굴을 찍으면 내 작업에 설득력을 가질 수 있지 않을까 생각했다.
자연 중 한 대상을 보자마자 포착할 수 있는 모습, 그리고 그 안에서 내가 바라보는 식물의 얼굴을 겹쳤다. 처음 식물의 인상을 찍은 후 그들을 관찰했고 그 식물의 얼굴에 대해 생각했다. 다음 날 같은 시간대에 다시 그들을 찾아 그 식물의 얼굴이라 생각되는 부분을 촬영했고 두 필름을 함께 스캔했다. 하루 동안 그 식물의 얼굴에 대해 생각하며, 짧은 시간 속 작은 변화들을 느끼며 식물의 포트레이트를 찍었다.
처음 내가 찾은 식물의 얼굴은 사람의 얼굴과 비슷했다. 동그랗고 모양이 있었다. 그리고 나는 그 안에서 눈, 코, 입도 찾으려 했다. 하지만 시간이 갈수록 있는 그대로 그들의 얼굴을 보기 시작했다. 찬 공기에 숨이 죽은 가지가, 붉게 물든 잎이, 시든 꽃이 보였다. 처음엔 보이지 않던 식물의 얼굴이 나타났다.
나의 식대로 바라보았던 많은 것들에 대해 그들이 답을 주었다.

Accidentally I scanned two films of one plant, making an overlapped image, and that image looked like a portrait of a plant. If I could shoot a plant like a portrait of a person, I thought I could have persuasive power of my work.
First, when I see one plant I want, I take a picture. I observe the plant and I find a face of the plant. Next day, in same time I go and I shoot again with another film. Finally I scan two films together. Thinking a face of a plant and feeling small changes in one day, I make portraits of plants.
At first I find a face of a plant which looks like a face of a person. It was roundy. I wanted to find eyes, nose and mouth in a plant, but as time went by, I just looked at their faces. I saw dead branches from cold air, red leaves and withered flowers. The faces of plants appeared which I did not see at first. They gave the answer to my approach.
</p>

<strong>초록의 시간 Green Time, Artist Statement </strong>
<p>
다시 실상사를 찾았을 때, 처음 촬영했던 식물들이 잘 있나 궁금했고 찾아가 보았다. 당연히 있으리라 생각했던 식물들은 대부분 사라졌고 남아있는 것들도 까맣게 타서 죽어 있었다. 자연과 작업하는 일은 언제고 찾아가면 찍을 수 있다고 생각하지만 실제론 그렇지 않다. 우리의 시간과 다른 식물의 시간 속에서 그들은 빠르게 변하고 또 사라진다.
실상사에서 작업하는 동안은 평소보다 식물과 오랜 시간을 가져보았다. 이곳에서 가장 크게 느낀 점이 ‘시간’이었는데 인터넷, 스마트폰, TV에서 벗어난 시간은 처음엔 매우 길고 더디게 느껴졌다. 마치 우주의 어떤 곳에 와서 다른 시간을 가진 것처럼. 하지만 날이 지날수록 긴 시간은 온전히 나의 것이 되었고 더 차분히 식물을 바라볼 수 있게 되었다.
작업 과정은 한 식물을 정하고 약 한 시간 반에서 두 시간의 촬영 시간을 가진다. 나름의 기준으로 식물을 고르고 첫 번째 촬영 후 20분의 간격으로 총 5번의 노출을 한다. 다음 촬영을 기다리며 식물을 관찰하기도 하고 시간을 공유해보기도 했다. 실상사에서만이 잠시 식물의 시간을 빌릴 수 있었다.
This work was conducted at a temple in Jiri Mountain. When I found the temple again, I wondered about the plants I had photographed first and went straight to it. Of course, most of the plants I thought were missing, and the remaining ones were black and dead. I think I can take a picture of nature work at any time, but it is not. In our time and other plant times, they change quickly and disappear.
While working in the temple, I took more time with the plants than usual. One of the most different things I experienced here was 'time', but at first it seemed to be very long and slow at the day off the internet, smart phones and TV. It 's like having some time in the universe and having another time. However, the longer day became more mine, and I could gaze slowly at the plants.
The work process defines a plant and takes approximately one and a half hours to two hours of shooting time. Select the plants on your own and take a total of five times multiple exposures every 20 minutes after the first shot. I watched the plants and shared short moments, waiting for the next shot. As the wind blows, as the sun changes, the plant time can be borrowed as soon as it changes.
</p>

<strong>0그램 드로잉 0g Drawing, Artist Statement </strong>
<p>
나무나 식물을 오래 바라보다 보면 마치 인터뷰를 하는 것 같다. 언어와 표정이 있는 사람의 대화와는 다르지만, 시간이 지날수록 그 나무를 알아가는 느낌이 든다. 해가 변하면서 나무를 비추는 빛도 변했고, 식물의 모습도 시시각각 달라 보였다. 처음 본 나무와는 다른 모습이 보였다. 이런 이야기를 한 장의 사진으로 보여주긴 쉽지 않았다. 그런 고민을 하던 중에 <0g Drawing> 작업을 진행하게 됐고 처음 씨앗과 필름을 같이 스캔한 순간, 이 작업과정이 내가 하고 싶은 이야기를 잘 보여줄 수 있으리라 생각했다.
필름과 씨앗을 고르고 스캔하는 과정은 직관적이고 주관적으로 진행된다. 어느 정도 씨앗을 모은 상태에서 작업이 진행됐기 때문에 촬영하며 그 장면에 맞는 씨앗을 상상할 수 있었다. 필름에서 씨앗의 위치를 구상하며 촬영하기도 하고 필름을 스캔하는 과정에서 여러 가지 씨앗을 테스트해보며 좋은 이미지를 찾기도 했다.
제목은 필름 위에 씨앗을 올려놓는 것이 마치 드로잉 하는 것 같았는데, 씨앗의 무게를 측정했을 때 대부분 1g도 되지 않는 것에 아이디어를 얻어 <0g Drawing>으로 정하게 되었다. 필름과 씨앗이 있다면 구성에 따라 많은 이미지가 만들어질 수 있지만, 여러 사진 중에서도 이미지로서 좋고 효과적인 것을 찾는 과정을 거쳤다.

If I look at trees or plants for a long time, it looks like I am interviewing. It differs from the conversation of people with language and facial expressions, but as time goes on, I feel like I know the tree. As the sun changed, the light shining on the trees changed, and the appearance of the plants looked different from time to time. It looked different from the first tree I saw.
It was not easy to show this story as one picture. I was doing <0g Drawing> while I was worried about it, and at the moment I scanned the seeds and film together, I thought that this work process would show me what I want to do.
The title was like putting the seeds on the film as if it were drawing, but when weighing the seeds, we got the idea that it was not more than 1g. The process of selecting and scanning films and seeds is intuitive and subjective.
</p>

<strong>내가 사는 나무 The tree I live, Artist Statement</strong>

<p>
경제개발정책에 의해 마들평야였던 노원구는 ‘아파트 숲’이 되었고, 예전의 모습은 찾아볼 수 없게 됐다. 많은 모습이 변했지만 오래된 나무들은 그 자리를 지키고 있었고, 그것을 기준으로 예전 모습을 추측하거나 상상할 수 있었다.
리서치를 하던 중 90년대 노원구에 대한 기사를 발견했다. 아파트가 급격히 지어지며 노원구의 오래된 보호수의 주변이 콘크리트로 덮이며 생육에 문제가 생겼다는 기사였다. 그리고 그 자리에는 아파트가 지어졌다.
노원구를 돌아다녔을 때, 실제로 많은 나무는 콘크리트에서 살고 있었다. 그리고 나무를 매개로 노원에 사는 주민들과 만나 대화했을 때, 단연 나무뿐 아니라 사람이 사는 환경 또한 예전과 많이 달라졌다는 것을 알게 됐다. 흙길이었던 것이 아스팔트가 되었고, 60채의 초가집이 수십 개의 아파트 단지가 되었다. 오래된 빌라는 사라지며 이웃들은 떠났고, 그 자리에 새로운 신축건물이 드러섰다고 한다. 노원구 또한 도시화가 진행되며 누군가의 집이 소멸하고 이웃이 떠나는 것을 경험했고, 또 아직도 진행 중이다. 노원구의 변화를 추적하면서 작업했다.
</p>

`,
//안정진
'JungjinAhn': `
<strong>시각의 관점 Perspective of Viewpoint, Artist Statement </strong>

<p>
나는 아직도 양쪽 눈을 번갈아가며 감았다 뜨곤한다. 우리의 눈과 세계가 서로에게 강하게 매듭지어져 있다는 걸 실감하는 순간이다. 우리 주변의 이러한 다층의 매듭들, 시각적이거나 의식적인 묶음들을 벗어나고 싶었다. ‘시각의 관점’은 시점의 두 축에 관한 작업이다. 소실점이 상실된 이상적 공간 인식에 대한 시도이자 더불어 일상적 주변 공간에 대한 전위적 접근이기도하다.
실제적 시각은 항상 소실점을 갖고 있다. 그것은 관찰자가 공간 속에 한 지점을 점유하고 있는 이상 벗어날 수 없는 물질적 육체적 한계이다. 하지만 의식속에서 재구성되는 공간에는 제한이 없다. 평면위의 선분들이 평행 하며 모든 계량적 요소들은 등척성을 갖을 수도 있다. ‘시각의 관점’은 이러한 이상 공간에 대한 시각적 재구성 이다. 그렇게 나오는 결과는 투시도법에 의한 전개도와도 같이 재현된 공간이다.
또 하나의 시점은 일상적 주변을 채우고 있는 평면들을 위한 것이다. 인식속에서 정신적 캔버스로서 작용하는 어떤 벽과 바닥들을 앞세워 서사적 주제에서 탈피하고자 했다. 실제적 변두리이자 인식적 변두리에 위치한 평 면이 장소성을 벗어나 그 자체로 구체적 대상이 되기를 바랐다.
이러한 작업을 위해 나는 변두리 공간 앞에서 카메라를 고정시켰다. 그리고 평면들을 복원하기 위한 지표들을 그리고 그 지표를 바탕으로 기하보정을 하였다. 투영 왜곡 보정을 위해서는 수학적 방식인 특이값 분해(SVD) 알고리즘을 사용하였다. 벽에 실제로 지표를 그리는 행위는 논리적 방식으로 치환되어 이상적 공간위에 평평 한 표면을 입혀주었다.

I still blink one eye after the other. At those moments I am reminded of how strongly our eyes are connected to our world. Then I wanted to be freed from those multi-layered bonds restricting our visual and logical senses.
‘Perspective of Viewpoint’ is a work concerning the two axes of our view. It is an attempt to understand ideal, vanishing point-free spatial perception and an avant-garde approach to the daily spaces surrounding us.
Technically, there is always a vanishing point in our view. We cannot deny that fact as we exist within the boundaries of the laws of physics. But a space that is constructed inside one’s mind knows no boundaries. There can be parallel segments on a one-dimensional flat surface, qualitative elements may be isometric. One aspect of the ‘Perspective of Viewpoint’ is the visual reconfiguration of the ideal space. The resulting space will be a reconstruction upon a perspective drawing blueprint. The other aspect concerns our daily spaces. It is an attempt to break free from epic subjects, substituting generally preferred objects by walls and floors that are the canvas of the mind. I wanted actual surface to be seen as an object itself, not as fixed spaces located within the boundaries of physical recognition.
To realize this work I fixed my camera in the edge of the target spaces, then set up indicators to restore plane areas and applied geometric correction based on those indicators. The mathematical algorithm SVD(Singular Value Decomposition) has been used to rectificate projection distortion. Configuration of actual indicators on the walls have been substituted by a logical process that built plane surfaces within the ideal space.
</p>

<strong>출구없음 No Way Out, Artist Statement </strong>
<p>
누군가의 사진을 바라볼때면 나의 시선은 매순간 촬영자의 시선과 동기화되어 촬영자가 보았던 그 순간을 함께 한다. 이것은 마치 일인칭 시점의 영화를 보듯 촬영자의 시선을 통해 여기가 아닌 어떤 다른 세계로 이어지는 경험이라고 느낀다. 이 곳으로부터 빠져나가, 사진이 보여주는 세계로 촬영자와 함께 서 있는 느낌을 받는다. 하지만 그 흐름의 방향은 일방통행이다. 시선은 일순간에 사진을 지나쳐 다음 세계로 달려나가, 밖으로 떠나버린다. 현실을 너무나도 자세히 모사하는 2차원 단편 매체로서 어쩔수 없는 듯하였다.
나는 빠져나가는 시선을 막아보고 싶었다. 그래서 여기에 사진을 두 장 두었다. 두장의 사진은 서로를 마주 보게 하였다. 단, 다른 하나의 것은 내가 바라보는 곳에서 볼 수 없었던 나머지를 바라볼 수 있게 하였다. 왕복하는 시선은 서로 반복적으로 교차하게 했다. 내가 어딘 가를 보고있을 때 그 어느 곳도 나를 보는 것처럼.
촬영은 한 장의 평평한 사진과 그 단편을 마주하는 다른 하나의 구형 파노라마 사진(spherical panorama)으로 구성하였다.
이 작업은 인간의 시각적 인식에 관한 나의 두번째 작업으로 다분히 느슨한 장소 설정이 있었다. 이미지 속의 대상인 장소들은 개인적인 혹은 사회적인 어쩌면 자연적인 경계 지점들이 되었다. 두개의 시선이 교차할 수 있는 곳이 곧 촬영 장소였다. 이미지를 난폭하게 다루는 기계적인 실험과 여전히 이미지들로 부터 심리적인 위안을 받는 나의 위태로운 정서라는 두 경계에 서있는 작업이었기에 그러한 장소 설정이 따라왔다. 또한 사진예술에 대한 사변적인 요구 혹은 무책임한 감상같은 스스로의 질문에 답하지 않기 위해 경계에 서있기로 했다. 그럼에도 나는 사진 이미지가 가장 친숙한 형태로 세계에 대한 감각을 제시해야 한다고 믿는다. 그렇기에 물리적인 두개의 시선외에도 세번째 이미지 자체라는 시선으로 확장되기를 바란다.

<p>
Upon encountering any photograph, I find myself synchronizing my gaze with that of the photographer to share the very fleeting moment captured. It is an experience analogous to watching a film shot from the first-person perspective, where the eye of the camera steers me into another world. It feels like I join the photographer in retreating into the world rendered in the photographic image. Yet, it is a one-way flow. My gaze stops at the photo transiently and runs out into another world before it flies off, which seems inevitable for a single 2D medium that simulates the reality too precisely.
I would like to keep the gaze from flying off. To that end, I have placed two photographs here face to face. One represents what my eyes see, whilst the other gazes upon what my eyes cannot reach. The two-way gazes alternately cross each other so that the place and I can look at each other at the same time. For shooting, one flat photograph is set to stand opposite to the other spherical panorama.
This is my second work concerning human visual perception with an obviously loose spatial setting. The places as the subjects in the images become personal, social or natural boundary points. The shooting place allows two gazes cross. Such a spatial setting in this work mirrors a border touching two aspects, viz. a mechanical experiment ruthlessly treating images and my precarious sentiment of psychologically being comforted by images. In addition, I have chosen to stand on the borderline lest I answer the questions raised by myself regarding speculative needs for photographic art or some unaccountable sentimentalism. Still, I believe photographic images should present a sensation of the world in the most familiar form to extend the gaze to a third image itself in addition to the two physical gazes.
</p>

<strong>기억의 숲 Forest of Souvenir, Artist Statement </strong>
<p>
아파트의 숲에서 자란 이에게 유년기의 기억은 회색일 것이다. 또한 그것마저 상실한 이에게는 회색 추억 마저 소중할 수 있을 것이다. 흔해 보이는 도로 포장 조차 지나간 기억으로 남은 형식들은 다시 돌아오지 않는다. 이것은 내 개인적인 경험을 바탕으로 하고 있다. 소위 아스팔트 키드라 불리는 성장환경을 갖고있는 나는 아파트에서 태어나고 자라났다. 삭막 하다고 표현해도 아쉽지 않은 곳 이었지만, 그것들 마저 재건축으로 잃고 나면 얼핏 같아 보이는 회색 도로 포장 마저도 그때의 것과 지금의 것이 다르다는 것을 인식하게 된다. 이러한 환경에 대한 기억의 최소단위를 이루는‘도시 표면’에 대한 기억 또한 소중한 것이 된다.
이 작업은 누군가의 추억이 될 재료에 대한 두가지 방향의 수집을 목적으로 하고있다. 기억을 숲과 나무로 상정할때, 첫번째는 나무에 해당하는‘표면’의 절취에 대한 것이다. 형상으로 인식되기 이전의 질감으로서 의 기억이 그것이다. 그리고 전자의 정반대에 해당하는, 어슴프레 눈을 감으면 떠오르는 전체적인 분위기 에 대한 기억이 있다. 이것이 숲에 해당하는 기억의 이미지이다. 이 두가지 양극단의 기억 을 한쪽은 기억의 최소단위로, 다른 한쪽은 배경음으로 설정한 사진 설치 작업이다.
도시환경이란 쉽게 변하고 사리지기 쉬운 연약한 것이다. 이 작업이 모든 것을 잃어버리고 추억 속을 더듬 거리기 전, 마지막 처방전이 되기를 바라며, 누군가에겐 위안이 되기를 바란다.

The childhood memories that grew up in the forests of apartments would be gray. Gray memories can also be precious to those who have lost it. Even common road pavements do not come back like the past.
This work is aimed at collecting two directions for the material that will be a memory for someone.When the memory is assumed to be a forest and a tree, the first is about the cutting of the "surface" corresponding to the tree. It is the memory of the texture before being recognized as a form.And there is a memory of the overall atmosphere that comes up when you close your eyes, which is the opposite of electrons. This is an image of memory that corresponds to the forest. It is a photograph installation work that sets the memory of these two extremes as the minimum unit of memory and the background of the other.
This is based on my personal experience.The urban environment is fragile and easy to change. I want this work to be the last prescription before I lose everything and stumble into memories, and I want to be a comfort to somebody.
</p>

`,
//정지현
'JihyunJung': `
<strong>Demolition Site, Artist Statement </strong>
<p>
철거현장<Demolition Site> 철거 예정인 건물에 잠입하여 내부를 빨간색으로 칠한 뒤 촬영하고, 실제 철거가 이루어진 다음에 다시 들어가 빨간 페인트의 흔적을 찾았다. 건물이 철거되면서 점점 작은 덩어리로 쪼개지는 빨간 흔적들을 기록하며 결국 한 점의 콘크리트가 되어 사라지는 빨간 방의 운명을 추적했다. 개인의 공간인 빨간 방은 재개발이라는 체제 앞에서 무기력하게 사라진다. 나는 이러한 과정을 통해 지금 우리가 사는 도시가 어떤 공간인지, 공동체 공간으로서의 도시가 어떻게 사라져 가는지 보여 줌으로써 맹목적으로 달려가는 도시화에 문제를 제기한다. 스펙터클한 철거 현장에서 개인 삶의 흔적이 존재하는 빨간 방을 찾는 과정은 도시를 삶의 공간으로 회복하고 재인식하는 작업이다. 지금은 완전히 사라진 인천 가정동 루원시티와 안양 덕천마을 철거 현장에서 작업했다.

I sneaked into a building that was scheduled to be torn down. Before I photographed them, I painted the inner walls into red. After the demolition I went in again, looking for traces of that red paint. By documenting the red walls that were broken down into smaller and smaller pieces as the building was destroyed, I chased after the fate of this red room that would, in the end, disappear into the void as an anonymous piece of concrete. The red room was a private space that dissolved when faced with a system of redevelopment. I reveal what kind of space the city we are living in is, and how the city as a place of community is vanishing. It is how I raise questions about the rash thoughtlessness of urbanization. Finding the red room where traces of an individual’s life still reside from within the spectacle of demolition is a work to recover and recognize the city as a place for living. The photographs were taken at the demolition sites of Lu 1 City in Gajeong-dong, Incheon, and Deokcheon maeul, Anyang. Neither site exists today.
</p>

<strong>Construction Site Dreg, Artist Statement </strong>
<p>
공사가 이루어지고 난 뒤에 남은 잔여물들을 수집하고 촬영하였다. 잔여물들은 모두 건축물이 완공되는 과정에서 일시적으로 일정 부분 기능하였지만 완공과 동시에 기능성을 잃어버리고 폐기된다. 이들은 잔여물로서의 형태를 일정기간 유지한 뒤, 다시 재 분류 및 가공되어 또 다른 기능을 위한 새것이 된다. 나는 바로 그 순간 그 잔여물들이 생명력을 얻고 완벽한 객체로 탄생된다고 생각한다. 건축에 사용되는 자재들은 나름의 기능을 가지며, 대부분 건축물을 구성하는 부분이 된다. 하지만 그 일부가 잔여물이 되어 폐기, 분류되고 또다시 새로운 자재들로 탄생하는 순환구조는 도시의 순환과 닮아있다. 기능주의가 미리 예측하지 못하고 만들어낸 이들 잔여물은 쓰레기가 아닌 자유로운 형태를 갖고 있는 ‘기능주의의 오브제’라 생각한다.

Building materials have their own function and they usually remain as part of the building. However, sometimes the materials are disposed, re-classified and then recycled as new materials. These remnants from the construction sites have become a good subject for my photography. Although the residues were partly functioning until the architecture is completed, they are disposed upon completion of construction, losing their functionalities and then are re-classified as a different object. However, I think this is the moment of rebirth to integral perfection. Remnants are the result of unforeseen calculation of functionalism but I consider them not as garbage, but as objects of functionalism without any fixed formats.
</p>

<strong>Red room, Artist Statement </strong>
<p>
재건축현장 <Reconstruction Site> 철거현장<Demolition Site> <Red room>라는 설치작업으로 발전시켜 작업하고 있다. <Red room> 철거로 인해 외부로 드러난 빨간색 공간의 일부를 확대하여 대형 실사 프린트로 출력해 의도적으로 만든 암실 또는 외부 공간 벽면에 실재 철거현장의 공간만큼 큰 사이즈로 부착하는 방식의 사진 설치작업이다. 이와 같은 설치작업을 통해 재건축현장<Reconstruction Site> 철거현장<Demolition Site> 작업하는 과정에서 빨간색 페인트의 흔적을 발견하는 순간을 전시 공간에 재현하고자 했다. 또한 설치라는 보여주기 방식을 통해 정해진 사이즈, 액자로 전시되는 일반적인 사진작업의 보여주기 방식의 한계를 극복해보고자 했다. <Red room> 2014년 단체전 『제36회 중앙미술대전 선정작가전, 예술의 전당』과 2015년 『콘택트 포토 페스티벌, 토론토』에서 선보인바 있고 앞으로도 국내외의 사진 설치 전시 위주의 포토페스티벌 등의 지원을 통해 작업을 이어나갈 예정이다.

<Red room>started with printing out a part of the red room, which was exposed outside because of the demolition. I filled the wall of an exhibition space in the dark room I intentionally made with the print. Then I installed spot lamps there and made them light up the red traces. I wanted to reenact the moment I discover the red traces. Also, by choosing installation as my display style, I tried to overcome the limitation of the traditional display style of photos, where all the photos are exhibited in regular sized-frames.
</p>

<strong>The darkside, Artist Statement</strong>
<p>
<The dark side 01-04> 북한과 인접한 백령도를 비롯한 서해5도의 일반인의 출입이 통제되는 군사지역을 촬영한 것이다.
백령도의 잔존 군사시설물은 한국전쟁 휴전 이후 직접적으로 활용된 사례가 없다. 때문에 군사시설물은 60년 동안 지속된 대치상태를 함의하면서도, 역으로 잠정적인 평화를 상징하는 양가적 대상이 되었다.
<The dark side> 이러한 군사시설물 위에 프로젝트를 영사하여 촬영한 평면 이미지 작업이다. 주간에 촬영한 군사시설물을 동일한 구도에서 영사하여 시간적인 배경이 다른 두 개의 이미지가 중첩된다. 이 때 주간에 촬영한 즉, 프로젝터로 영사하는 이미지는 수작업-포토샵을 통해 군사 시설물을 제거했다. 따라서 영사된 이미지에서 군사시설물은 그것이 거기 있다는 걸 알아보기 힘들 만큼 숨어 있다.

<The dark side 01-04>is about military zones such as Baengnyeongdo (island) and 5 islands in the West Sea where civilian access is prohibited. Residual military facilities have never been directly used since Korean War. Thus these military facilities became double targets symbolizing peace while they contain military confrontation that has been persistent for 60 years.
<The dark side> is a work of projected images on military facilities. 2 images of military facilities in different time of daytime are overlapped by projecting them in the same angle. In this case, the military facilities in the projected image taken during daytime are removed by Photoshop. Consequently military facilities in the projected image are hidden, so it is hard to recognize that they existed there.
</p>

`,
//김태중
'TaejoongKim': `
<strong>Foresta, Artist Statement </strong>
<p>
나는 숲 속에서 빛을 찍어왔다. 나는 사진을 찍을 때 카메라 뒤에서 인공광을 이용해 100번에 가까운 빛을 쏘며 뛰어다닌다. 셔터가 열려 있는 동안 카메라 바디는 삼각대 위에 고정되어 있지만 내 몸은 계속 움직인다. 그리고 카메라는 내가 만든 빛을 모두 기억한다.

I have taken a light in a forest. I walk and run behind my camera with approximately 100 times lightings when I make my artwork. My camera body is fixed on tripod, but my physical body is moving while the shutter is opened. My camera remembers all the light that I made.
</p>
<strong>Foresta-reverse, Artist Statement </strong>
<p>
'Foresta - reverse'의 사진들은 숲의 한 면과 그 이면을 정반대의 방향에서 같은 거리와 화각으로 촬영한 사진이다. 사진과 영상 프로젝션이 결합된 'Foresta - reverse'의 설치 작업은 빛과 시간에 대한 연구 과정이다.

The 'Foresta - reverse' is a work of photographing one side of the forest and completely the opposite side of it in the same distance and angle. The 'Foresta - reverse', the photograph work combined with the video projection, was completed after the preliminary research as to the light and time.
</p>
`,
//유영진
'YoungjinYoo': `
<strong>NOWHERE, Artist Statement </strong>
<p>
이 세상에 존재하는 공간들은 개인의 소유권이 명확한 듯 보이지만 그렇지 않다. 사람들은 모든 공간을 물리적, 혹은 감정적으로 가졌다고 믿는다. 하지만 공간은 끊임없이 사람들 속에서 공유된다. 사람들은 공간을 개인이 소유할 수 있다는 착각 속에서 사적인 공간을 찾으려 애쓴다.
나는 온종일 제자리에서 시간대를 나누어 촬영했다. 시시각각 변하는 빛의 모습들, 그에 따라 변하는 그림자들을 지켜봤다. 시간이 지남에 따라 변하는 분위기와 색들을 나의 모든 감각에 의존하며 포착해냈다. 그리고 그 모든 것들을 한 장의 사진에 조합하는 작업을 해왔다. 한 순간에 담을 수 없는 빛과 긴 시간이 조합된 이 공간은 세상에 존재하면서도 또한 존재하지 않는 곳이 되었다. 감각으로 인지할 수 있지만 세상에는 존재하지 않는, 현실에 존재하지만 동시에 부재한 장소. 그것이 나의 ‘NOWHERE’이다.

In this world, all places seem to have clear ownership, but it doesn't. People believe that they own the places physically or emotionally, but the places are shared endlessly. People misunderstand that they can own any places so they try to find out a private place.
I took this picture in one place at different times within a day. I could feel changes of the light and according to it, I could also feel changes of shadows minute by minute. Relying on my senses, I captured the changing air and the color as time went by. Finally, I mixed everything I shot in one picture. This place which contains a combination of light and long period of time became something that exists and doesn't exist at the same time. This place can be felt by senses but does not exist in this world, absented place. It is called ‘NOWHERE’.
</p>

<strong>In a Twinkle, Artist Statement </strong>
<p>
나는 나의 고등학교 졸업앨범 사진을 보다 문득 같은 반이었던 친구들의 연락처를 알아내서 일일이 전화를 했다. 나는 친구들에게 고등학교 때 같은 반이었던 친구들의 이름을 아무것도 보지 않은 채로 기억나는 만큼만 말해달라고 했다. 그 중 한 친구는 아무도 기억나지 않는다고 말했다. 그녀는 그때가 잘 기억이 나지도 않을 뿐만 아니라 기억하고 싶지도 않다고 말했다. 내가 그녀에게 전화를 걸었는데도 불구하고 그녀는 내가 같은 반이었는지조차 기억하지 못했다.
사진을 보면서 사람들은 기억한다. 그래서 너무나도 쉽게 사진을 기억이라고 얘기한다. 같은 반 친구들의 기억 속에는 38명 모두가 존재하고 있었다. 하지만 이름과 얼굴을 또렷하게 기억하는 이가 있었고, 존재했다는 사실만을 기억하는 사람도 있었다. 몇몇은 각자의 기억 속에서 이미 사라져버렸던 것이다. 각자의 분열된 기억들 속에서 단체 사진 속의 반은 모두 해체되었고, 이젠 하나일 수 없게 되었다. 그리고 나는 개인이 기억하는 반의 부분들을 모아서 하나의 반을 만드는 작업을 진행했다.

Looking at my high school graduation photo book, I looked up all the contact numbers of my classmates and called each of them. I asked my classmates to tell me the names of friends that they remember. One of my friend said that she couldn’t remember any of them. She also said that she can’t remember her high school time and also doesn’t want to remember that. She couldn’t even remember that I was in the same class with her.
People remind their memories by looking through the pictures. So we easily say pictures are our memories. In my friends' memories, all 38 friends exists. However, there are some who remembers all the faces clearly, but there are also some who remember only some of them. Some were erased in our memories. In those cracked memories, the class in the picture is all scattered and can't be joined as one. Therefore I gathered all individual pieces of memories to make a whole class again.
</p>

<strong>풍화 The Weathering, Artist Statement </strong>
<p>
사람들은 사진 속의 기록된 이미지를 바라보며 그 장면을 자신의 ‘기억’이라고 말하는 모습을 본 적이 있었다. 심지어 나조차도 어떤 장소나, 순간을 기억하기 위해 사진을 찍어대곤 한다. 사진 속의 선명하고, 명료한 이미지의 힘은 내가 기억하고 있는 모습과 순간들도 선명할 것이라고 착각하게 만든다. 나에게 인상깊었던 장소들에서 작업을 하면서 느꼈던 내가 아예 인식하지 못했던 것들, 그 자리에 존재했지만 나는 볼 수 없었던 것들에 대해서 많은 고민을 했었다. 기억은 머리 속에 입력되는 순간부터 끊임없이 그 모습이 변한다. 그것이 점점 지워져 사라지는 것이든, 다른 기억들과 점점 뒤섞여 새로운 것이 생겨나든 사진처럼 명료한 이미지는 아닐 것이다. 불완전한 기억이미지는 사진과 마주하는 동시에 어느 정도 지워진 부분이 채워지기도 하지만, 그것도 순간일 뿐이다. 우리의 기억은 끊임없이 무너지고, 부숴지고, 지워지며, 사라진다.

Picture is the record of past things or disappeared things. People say that pictures are their memories. Even myself, I take pictures to remember some places and the moments. The power of image makes me think my memories will be as clear as the pictures. I thought about the things that I couldn't even recognize when I was working in special places, and the existing ones that I could not see.
And I asked myself that those pictures can surely replace all the memories. I believe that memories change as soon as they are put in our brains. Whether it is faded and disappeared or created by mixing up with different memories, it can't be a clear image as a picture. Unsettled memories can be filled up a bit with the pictures but it's only for a little moment. Our memories are keep draining, breaking, expiring, and disappearing.
</p>

`,
//최은주
'EunjuChoi': `
<strong>New-Family Portrait </strong>
<p>
집에 들어서면 거실 한켠 제일 잘 보이는 공간에 큰 가족사진이 걸려있다. 나는 화목하고 행복해 보이는 이상적인 가족사진을 보며 본인 가족의 실직적인 관계에서 이질감을 느꼈다. 일반적인 가족사진을 보면 한 집안의 가족 구성원이 어떻게 이루어져 있는지 사진을 통해 유추해 낼 수 있지만, 판에 박힌 듯 비슷한 모습을 하고 있는 가족사진의 특성상 진정한 가족 구성원들의 관계가 어떤지는 알 수가 없다. 가족사진은 가족 구성원들의 친밀함과 안정된 관계를 기록하고 보여주기 위한 용도로 사용되기도 한다. 하지만 일반적인 형태의 가족사진에서는 가족의 단편만 볼 수 있으며, 진실성 있는 실질적인 관계를 알아보기는 어렵다. 이를 보며 가족사진이라는 것이 모두가 다른 인격체로 이루어진 가족을 하나의 집단으로 이어주는 매우 중요한 역할을 하는 것처럼 보이면서도, 가족의 연출된 모습만을 보여주는 상징적인 물건일 수도 있다고 생각했다.
나는 연작 <New-Family Portrait>에서 전형적인 가족사진의 형태를 이용하여 ‘가족사진’이 가지고 있는 고정관념과 이중성을 작업으로 나타내고자 했다. 총 11개의 여러 가지의 가족의 형태를 만들었고, 참가자들을 우연성을 기반으로 한 제비뽑기를 통해 새로운 가족을 꾸렸다. 이러한 실제의 가족인 아닌 사람들을 전형적인 가족사진의 형식으로 기록하는 모순적인 방식을 통해 가족과 가족사진의 고정관념과 이중성을 나타내고자 했다.

When I enter my house, a large family portrait of my family come in at first sight. I felt contradictory every time I look at the family portrait that looks like a ideal happy family. A family portrait shows how a family is formed, but because of the stereotype of family portrait, it is hard to tell the true relationship of a family. Family portrait is important because it plays the role of bringing the family together. But at the same time, it is meaningless because it seals the true family figure and only shows the ideal family figure.
In the series <New-Family Portrait> I have made a total of 11 different types of families, and 42 participants formed a new family by random drawing lots. Through the contradictory method of recording people who are not real families in the form of a typical family portrait, I tried to talk about the stereotypes and duplicity of ‘family portrait’.
</p>

<strong>新 한국의 가족 New Korean Family </strong>
<p>
한국 사회는 해방 이후 현재까지 급속한 경제·사회 발전을 거쳐왔고, 이와 관련하여 일반 적인 가족의 형태 역시 급격한 변화를 겪어왔다. 주명덕 작가는 연작 <한국의 가족>을 통해서 당시 대가족에서 핵가족으로 변화하는 1970년대의 다양한 형태의 가족을 사진으로 기록하였다. 나는 수십 년 전의 가족의 여러 형태를 기록한 사진을 보며, 가족의 개념은 바뀌었지만, 가족과 가족구성원에 대한 고정관념과 역할은 크게 변화하지 않았다는 것을 느끼게되었다.
연작 <新 한국의 가족>에서 고정관념적이고 전형 적인 가족사진 속 인물들을 실제의 가족이 아닌 타인으로 대체하는 시도를 통해서 가족사진의 고정관념적인 정형성의 허구성을 강조하며, 급 격하게 변화해온 현대 사회의 가족 개념과 현실 안에서 나타나는 이질 적인 요인을 밝히고자 했다.

Korean society has undergone rapid economic and social development since its liberation, and the general form of family has also undergone drastic changes. The series <New Korean Family> was inspired by “Korean Family” by Joo Myung-Duck, who recorded families in the 1970s during the period of change in a large family to the nuclear family in Korea. But when I saw pictures of various forms of family decades ago, I felt that the concept of family has changed, but the stereotypes and roles of family members have not changed much.
In the series <New Korean Family>, I wanted to recreate the family in his photographs which is taken half a century ago, maintaining the composition but with individuals who are not a true family. The final image looks like a real typical family but, all the components including props, background, character, and the relationship is all fiction.
</p>

`,
//정영호
'YounghoJung' : `
<strong>Monologue Protest </strong>
<p>
상식/비상식 경쟁: 생각의 유통 경로로서의 집회
특정 상식은 특정 시대에만 유효하다. 특정짓기 어려운 어떤 시점에 과거의 상식들이 비상식으로 간주되기 시작한다. 거시적인 역사의 관점에서 상식의 범주는 역동적인 궤적을 남긴다. 과거의 상식을 현재의 비상식으로 추동하는 역학을 해체하면 현재의 비상식으로 미래의 상식을 상상할 수 있다. 상식과 비상식 사이에서 판단되지 못한 상들을 생각이라 부른다. 발현된 생각들은 판단과 경쟁의 장에서 시험된다. 어떤 국가의 공론장은 어떤 생각들을 상식으로 분류하고, 내재화하여 규범화한다. 어떤 국가의 공론장은 어떤 생각들을 비상식으로 분류, 터부시한다. 특정 사회가 지니는 시대적 조건, 기술적 조건, 문화적 조건들은 각 문항의 시험지가 된다.

집회의 주장만 남기고 맥락을 제거하기
독백집회 시리즈는 오프라인 공간에서 생각이 유통되는 경로인 집회 현장 속 구호들의 보편적 유효성을 검증하는 시도이다. 대규모의 집회 혹은 시위는 어떤 큰 자국을 남기기 위한 시도이다. 구호의 실현 여부를 떠나, 집회 현장은 가장 최신의 혹은 미완성의 생각들이 시험대에 오르는 물리적 장소이다. 특정 시대의 생각은 한정된 환경에서만 유효하게 작동한다. ‘집회’라는 통합적 환경에서 집회가 이루어지는 장소와 참여자들의 성별/세대/인종/외모/목소리를 분리시켜 주장만을 여과해내고 고립된 환경에 위치시킨다. 이를 통해 주장이 가지는 시대적 유효성/한계성을 검토하고 사회의 환경과 맞물리는 생각의 시대의존성을 드러내고자 한다.

Common sense v. uncommon sense: Protests as the voice of the thoughts
Some common sense is only valid for some eras, with common sense of the past unwittingly becoming uncommon sense at indefinable moments. History tells us that common sense tends to leave an indelible trace across a wide spectrum of life. One can therefore predict common sense of the future by unraveling the dynamics behind the shift from common sense of the past to uncommon sense of the present. The unexplainable sense between common and uncommon senses is defined as “thoughts” that, when expressed vocally, are put to the test for judgment and competition. Whereas some countries classify, internalize and institutionalize certain thoughts as common sense upon test in the public realm, others reject them as uncommon sense and taboos. The thoughts are assessed against the criteria of the time, technology and culture inherent to a specific society.    
Taking protest messages out of context
The Monologue Protest series is an attempt to validate the universal effectiveness of protest slogans that find power in physical space. Large-scale assemblies and protests aim to leave a profound mark in society. Protest sites serve as the physical place where the latest or incomplete thoughts are put to the test, regardless of whether the slogans are turned into reality or not. The thoughts of certain times are only valid within limited context. The gender, generation, race, look and voice of the protesters and the protest locations are taken to pieces to separate a part—message, from the whole—protest, and isolate the message. This seeks to examine the validity and limitation of such messages with respect to the needs of the times, and reveal the time-dependency of thoughts that mirror the general social views.
</p>

<strong>Lightless Photography </strong>
<p>
기계와 유령
디지털 카메라 센서의 자체적인 노이즈는 이미징 메커니즘 성질의 기본값이다. 이는 빛이 없는 상태에서도 사진 이미지를 생성하는 결과를 낳는다. 몇시간 동안 암흑 상태를 향해 열려 있는 셔터가 남기는 사진은 신호로서의 사진을 상기시킨다. 기록된 노이즈는 피사체를 촬영한 사진이 아니라 사진을 촬영한 사진이 된다. 동시대 사진이 속성을 드러내는 방식은 신호 메커니즘을 바탕으로 하는 픽셀의 가소성으로, 물질 사진과 대비된다. 화학적 과정의 필름 사진에서 파라미터 값은 전자적 과정의 디지털 사진에 반비례한다. 상을 확대할 때, 필름 사진은 입자를 드러내고 디지털 사진은 입자를 감춘다. 필름 사진의 유령은 메커니즘 밖에, 디지털 사진의 유령은 메커니즘 안에 존재한다. 거친 노이즈 사진이 용량을 더해가며 증폭되면 해상도가 높은 흐릿한 이미지가 생산된다. 픽셀의 이진법은 벡터값으로 둔갑해 균질한 사진을 외양을 갖는다. 이 때, 사진과 이미지의 교집합과 합집합은 일치한다. 화학적 사진과 전자적 사진, 벡터와 비트맵, 사진과 이미지 사이의 경계없는 환경에서 동시대의 바라봄이 존재한다.

Machines and ghosts
The sensor noise produced by digital cameras is a default property of the imaging mechanism, which allows for the formation of images even in the absence of light. The shutter, which stays open for hours in pitch darkness, serves as a reminder of signal-like photographs. Now, all the generated noise turn into photographs of photographs, not photographs of objects. Contemporary photography stands in contrast to analog photography in that all images made up of pixels are digitized. The camera intrinsic parameters used during capture differs between film-based photography and digital photography in that the former involves chemical reactions while the latter relies on digital processes. Meanwhile, whereas magnified images make the grain more pronounced in photographic film, this is nowhere present in digital photography. The ghosts of film and digital photography hence inhabit outside and inside the imaging mechanism respectively. When greatly enlarged and amplified, the gritty noise produces a high-resolution, yet blurry image. Binary numbers that represent pixels are converted into vector values to take the form of the sharpest photography, leading the union and intersection of the photograph and image set to correspond. Contemporary views can truly go mainstream where the lines between chemical and electronic photographs, vectors and bitmaps, and photographs and images are blurred.

<strong>Unphotographable Cases 기술이 창조한 생각: 기술 업데이트와 생각 업데이트의 역학</strong>
천문학이 망원경에 관한 학문이 아닌 것처럼 컴퓨터 과학은 더 이상 컴퓨터에 관한 학문이 아니다. -E.W.다익스트라 오늘날 사회의 상부구조를 결정 짓는 강력한 하부 구조는 경제보다는 기술이다. 본질적으로 축적적 성취를 증명하는 기술은 시간의 축을 따라 기하급수적 영향력을 증대시킨다. 시간의 편에 선 기술의 하부구조의 업데이트는 상부구조의 업데이트를 추동한다. 상부 구조의 문화, 관념 등의 상식들은 과거의 패러다임과의 결별을 촉구한다. 과거의 생각이 빠르게 과거로 귀환하고 현재의 생각은 과거에 편입되며 미래의 생각은 현재를 설득한다. 미디어 기술은 소통 환경을 극적으로 조성한다. 오늘날 공론장은 오프라인에서 존재하지 않으며 완벽하게 온라인 공간으로 편입됐다. 온라인 커뮤니티, 인플루언서, 트위터, 페이스북, 유튜브 채널은 디지털 플랫폼을 통해 사상과 의견을 유통시킨다. 디지털 여론장은 가장 뜨거운 생각 경쟁의 장으로 현존한다. 가상 세계의 여론이 실제 세계의 여론을 지배한다. 디지털 플랫폼에 존재하지 않는 여론은 실제 세계에도 존재하지 않는 목소리로 취급된다. 공간을 극복하는 사용자 기반 소통 환경은 국소적으로 해체됐던 미약한 신념 집단조차 가상 공간에서 집결시킨다. 뉴미디어 환경이 소수 의견의 휘발을 방지하면서 공론장은 재구성된다. 소통의 효율과 양의 증가가 생각의 집결과 경쟁을 고양시킨다. 소프트웨어 아키텍처는 메시지가 된다.

가상 공간의 생각과 사건 촬영하기
공론장이 오프라인에서 온라인으로 변모함에 따라 일상의 논쟁과 격동은 온라인으로 이주하였다. 온라인과 오프라인이 역할을 분담하면서 진실의 위치는 오히려 가상 공간으로 역전되었다. 역설적으로 뜨거운 불편함은 익명성을 전제로 가능한 온라인 공간에 정착한다. 익명 기반의 온라인 디지털 플랫폼은 전례없는 눈과 귀의 공론장을 형성하는데, 공간은 소프트웨어 아키텍쳐로 구조화된다. 새로운 소프트웨어 아키텍처는 여론과 정보가 흐르는 경로를 선별/배포하는 과정을 결정하는데 늘 성공적이지 않다. 소수의견과 가짜뉴스의 경계는 흐릿해지고 정보와 선동 사이의 정교한 분류를 요구한다. 소프트웨어의 아키텍처를 개선하려는 노력과 함께 알고리즘을 이용하려는 세력도 존재한다. 비사진적 사례들 시리즈는 생각이 유통되는 온라인 공간의 신호활동을 물리적으로 촬영하고자 하는 시도이다. 신호로서만 존재하는 여론과 정보의 무형성 데이터를 가공하여 3D 프린트하여 사진으로 촬영 가능한 형태로 환원한다. 특정 키워드의 관심도와 검색 빈도는 독해 불가능한 형태로 조작되고 형식적 개연성을 잃은 물리적 증거물이 된다. 손으로 만져지는 증거물은 다시 스크린 속 그래픽 이미지의 외양을 쫓는다. 말할 수 있는 것은 일상의 표면에서 은퇴한 진실의 자국과 소프트웨어 아키텍처 소통 앞에 놓인 사진의 불가능성이다.

Technology-initiated thoughts: The dynamics of evolution between technology and thoughts
Computer science is no more about computers than astronomy is about telescopes. -Edsger Wybe Dijkstra Technology is the robust sub-structure that supports the superstructure of the modern society – more so than the economy. Technology, which intrinsically builds on past accomplishments, creates exponential impact in the course of time. As technological progress speeds up over time, the evolution of the sub-structure spurs the co-evolution of the superstructure. Common sense, including the culture and concepts of the superstructure, demands that we let go of the conventional paradigm. Now, thoughts of the past quickly return to the past, thoughts of the present blend into the past, and thoughts of the future convince the present of what is to come. Media technology develops a plethora of avenues of communication. The public sphere has completely moved online, with online communities, influencers, Twitter, Facebook, and YouTube all leveraging digital platforms to diffuse ideas and beliefs. The digital public sphere has become the most vibrant platform for intense public debate. Now, public opinion in cyberspace not only prevails over that in physical space, but also dictates the rules. As a result, views unavailable on the virtual platform are treated as totally nonexistent. The user-driven online communication platforms that transcend space also have the power to bring together even the most distant groups of people who have slowly drifted away. As the new media environment encourages the minority opinions to be heard, the public sphere is re-formed. Efficient and vibrant communication gives new impetus to the process by which thoughts converge and diverge, and leads to the emergence of the software architecture as the message.
A photographic record of virtual thoughts and events
As the public sphere makes the transition from physical to virtual, the everyday debates and altercations have also moved online. With offline and online platforms each performing their respective functions, truth has found a new place in cyberspace. Paradoxically, the virtual space is now where the most inconvenient stories dwell, as anonymity is protected on the internet. This led to the rise of an extraordinary online public sphere that brings together different views and voices. The space, which runs on a software architecture, is yet to be fully successful in selecting and distributing the channels through which information and public opinion flow. The lines between minority views and fake news are blurred, and a precise line needs to be drawn to set information apart from instigation. Two opposing forces are also at work, with one striving to improve the software architecture and the other exploiting the algorithm. The Unphotographable Cases series attempts to physically capture the signals in the virtual space where thoughts travel. The public opinion, which only exists in the form of signals, is processed and 3D printed with information as formless data to render images that can be photographed. The popularity and search frequency of specific keywords are encoded into an unreadable form, only to serve as physical evidence that no longer belongs in a particular form. The tangible evidence now chases the unchaseable—the visual elements of the graphic images. What can be detected at all are some faint traces of the long-gone truth and the unphotographable layers of digital communication.
</p>

<strong>Face Shopping 기술의 돌기seam 드러내기 </strong>
<p>
AI가 생성한 존재하지 않는 가상의 인물 사진은 스크린에서 놀라운 실감을 드러낸다. 사진 스톡 사이트에서는 수만 가지의 인물 사진을 제공하는데 외모 선택사항이 매우 다양하다. 얼굴 포즈, 성별, 나이, 인종, 눈동자 색상, 머리카락 색상, 머리카락 길이, 감정 표현 등을 선택할 수 있다. 사이트의 2,685,868 장의 인물 사진 중에는 각각의 선택사항이 적용됨에도 불구하고 모호한 경계의 사진을 찾을 수 있다. 미소와 무표정 사이, 남성과 여성 사이, 타인과 형제 사이. 스톡 사이트에서 제공되는 사진은 이미 수많은 사진 광고에 활용되고 있고 나아가 딥페이크 기술의 접목을 통해 방송 영상업에도 주목되는 기술로 떠오르고 있다. 불쾌한 골짜기가 과거로 소멸되면서, Seamless 테크놀로지의 seam을 드러내는 유일한 방법은 장치를 드러내는 전략이다. 소프트웨어로 구축된 빅데이터와 하드웨어의 컴퓨팅 파워가 생성한 결과물을 네트워크로 수신받는다. 최종 수신지로서의 개인 유저의 디바이스는 마지막 구현 output 장치가 된다. 스마트폰에 나타난 인물 사진들을 크게 접사 촬영은 그들의 피부가 빨강, 초록, 파랑으로 드러나고 있음을 새삼 귓속말 해준다. 이어지는 귓속말은 또 다른 가능성을 들려준다. 포토 스톡 사이트가 생물학적 아기의 외모, 성향을 결정짓는 미래의 출산 상담 소프트웨어를 닮았다는 가능성. 귓속말은 이어진다.

Exposing the seam
AI-created portraits of virtual persons are stunningly realistic. Tens of thousands of portraits are available on stock photography sites where images can be searched and filtered by lists of keywords such as face position, gender, age, race, eye color, hair color, hair length and emotion. Despite the many search filters, boundary ambiguity is still present in a number of photos out of the 2,685,868 portraits: portraits that sit somewhere between smile and no emotion, men and women, strangers and family. Photos available on these sites are already used in a great many of ads and increasingly spotted across the visual media industry with deepfakes evolving into an enabling technology. With the uncanny valley becoming a thing of the past, the only way to expose the seam of the seamless technology is to lay bare the device. The output generated by a combination of big data built on software and computing power of the hardware is received over a network, flowing toward its final destination—individual users’ device that provides the output. Close-up macro shots of portraits on smartphones gently allude to the red, green and blue colors of the skin. The photos further allude to another possibility, the possibility of the stock photography sites bearing a strong resemblance to future birth counseling software that determines babies’ appearance and personality. In soft murmurs, the allusions continue.
</p>

<strong>풍화 The Weathering, Artist Statement </strong>
<p>
사람들은 사진 속의 기록된 이미지를 바라보며 그 장면을 자신의 ‘기억’이라고 말하는 모습을 본 적이 있었다. 심지어 나조차도 어떤 장소나, 순간을 기억하기 위해 사진을 찍어대곤 한다. 사진 속의 선명하고, 명료한 이미지의 힘은 내가 기억하고 있는 모습과 순간들도 선명할 것이라고 착각하게 만든다. 나에게 인상깊었던 장소들에서 작업을 하면서 느꼈던 내가 아예 인식하지 못했던 것들, 그 자리에 존재했지만 나는 볼 수 없었던 것들에 대해서 많은 고민을 했었다. 기억은 머리 속에 입력되는 순간부터 끊임없이 그 모습이 변한다. 그것이 점점 지워져 사라지는 것이든, 다른 기억들과 점점 뒤섞여 새로운 것이 생겨나든 사진처럼 명료한 이미지는 아닐 것이다. 불완전한 기억이미지는 사진과 마주하는 동시에 어느 정도 지워진 부분이 채워지기도 하지만, 그것도 순간일 뿐이다. 우리의 기억은 끊임없이 무너지고, 부숴지고, 지워지며, 사라진다.
Picture is the record of past things or disappeared things. People say that pictures are their memories. Even myself, I take pictures to remember some places and the moments. The power of image makes me think my memories will be as clear as the pictures. I thought about the things that I couldn't even recognize when I was working in special places, and the existing ones that I could not see.
And I asked myself that those pictures can surely replace all the memories. I believe that memories change as soon as they are put in our brains. Whether it is faded and disappeared or created by mixing up with different memories, it can't be a clear image as a picture. Unsettled memories can be filled up a bit with the pictures but it's only for a little moment. Our memories are keep draining, breaking, expiring, and disappearing.
</p>

`,
//정찬민
'ChanminJeong': `

`,
//크리스티안
'CristianDoeller': `
<p>
Christian Doeller is a visual artist, artistic researcher and educator. His practice develops at the intersection of perception, philosophy, scientific research and technological change. With his generative images, sculptures, participatory formats and process-based installations, he is exploring entanglements and transitions between physical and digital realities. He investigates the foundations, limits and readjustments of perception, relationships and dependencies in human-machine interactions and the social effects of digitisation processes. Coming from from the context of photography, he is generally involved with technical sensors and their potential for translating, observing and interpreting environmental phenomena. Christian Doeller is a research associate at the chair for Media Environments at the Bauhaus-University Weimar and a member of the curatorial team at KV - Kunstverein Leipzig. He lives and works in Leipzig.
</p>

`,
//정승원
'SeungwonJung': `
<strong>Memories Full of Forgetting, 2017- </strong>
<p>
“이 경우 공간은 전부이다. 왜냐하면 이제 와서 기억을 생생하게 하는 것은 시간이 아니기 때문이다. 기억은—얼마나 기이한 일인가!—구체적인 지속을, 베르그송적인 뜻으로서의 지속을 새겨 놓지 못한다. 없어진 지속을 우리들은 되살 수 없는 것이다. 그것을 우리들은 생각할 수만, 구체성의 두터움을 모두 빼앗겨 버린 추상적인 시간의 선을 따라 생각할 수만 있을 따름이다. 우리들이 오랜 머무름에 의해 구체화된, 지속의 아름다운 화석들을 발견하는 것은, 공간에 의해서, 공간 가운데서인 것이다. 무의식은 머무르고 있는 법이다. 추억은 잘 공간화되어 있으면 그만큼 더 단단히 뿌리박아, 변함없이 있게 되는 것이다. […] 내밀성을 알기 위해서는 날짜를 밝히는 것보다 우리들의 내밀성의 공간들 가운데서 위치를 찾아내는 것이 더 긴급한 일인 것이다.”
— 가스통 바슐라르 ⟨공간의 시학⟩

기억을 생생하게 하는 것은 시간이 아니라 공간이다. 공간은 우리가 존재할 수 있거나 어떤 일이 발생할 수 있는 자리가 된다. 물질로부터 독립한 무형의 공간도 있다. 우리 의식에 위치하는 빈 공간, 간극, 기억의 왜곡은 종종 ‘과거’를 향한 우리의 믿음을 오도하고, ’기억’에 대한 우리의 이해는 여전히 불완전하다.
우리는 공간 속에 특정한 시간들을 압축, 공간화한다. Memories Full of Forgetting(2017-)은 이처럼 우리가 공간에 관하여 기억과 시간을 어떻게 인식하는지를 고민한다. 사진을 프린트한 직물은 내가 (기억을) 옮기고, 덧붙이고, 삭제하는 작업의 표면이 된다. 한 폭의 천—완전함—에서 시작해 실을 한 올 한 올 뽑아내어 기억의 이미지를 물리적으로 조각내고, 이것을 덧붙이고 삭제하고 재구성하는 반복적인 과정을 천에 기록한다. 결과적으로 이미지들은 생성과 안착 이후의 과정, 해체를 통해 변형되고 구성된다.
직물에서 물리적으로 비워낸 공간은 그 어떤 시각적 정보보다 중요하게 작용한다. 나는 공간을 통해 존재와 의식, 기억에 자리하는 간극을 응시한다. 실을 한 올 한 올 풀어내는 과정은 망각과 지식의 누락, 잔류하는 기억의 불완전함을 상기하고, 풀어낸 가닥을 다시 바느질함으로써 나는 사라진 기억이 쌓인 망각의 저장소를 재구성한다. 이러한 작업 과정은 잔류하는 기억의 흔적이 남기는 것—무의식적으로 우리가 과거를 어떻게 재편하는지—을 강조한다.

<p>
“Here space is everything, for time ceases to quicken memory. Memory—what a strange thing it is—does not record concrete duration, in the Bergsonian sense of the word. We are unable to relive duration that has been destroyed. We can only think of it, in the line of an abstract time that is deprived of all thickness. The finest specimens of fossilised duration concretised as a result of long so­journ, are to be found in and through space. The unconscious abides. Memories are motionless, and the more securely they are fixed in space, the sounder they are. […] For a knowledge of intimacy, localisation in the spaces of our intimacy is more urgent than determination of dates.”
— Gaston Bachelard ⟨ The Poetics of Space ⟩

Space can be found in the most concrete of objects—but there is also intangible space, gaps in our own cognitive processes: memory distortions often mislead our own beliefs of the past, and our understanding of the pathways in the human brain is still incomplete.
Memories Full of Forgetting(2017-) focuses on how people perceive memory in the context of space. Printing fragmental photographic images on fabrics, I use this as a surface upon which to work into, apply onto and remove from. I begin with a complete object—a length of fabric—and chip away it’s physical presence. I submit this to a series of repeated gestures of erasure and reconfiguration, including de-threading, unpicking, rethreading and reconfiguring. As a result, images are transformed by deconstruction more often than by application.
Empty space within the physical fabric become as important as any descriptive visual information. Through the creation of empty space, I consider the gaps in our existence, consciousness and memories. By removing threads from the fabric, I reference the neurological process of forgetting and memory loss, and the imperfect nature of what is left behind. By stitching back the extracted strands, reminiscences gather to reconstruct a storage of oblivion full of memories. Here my process highlights the omissions in our knowledge, but also the ways their remaining traces can unconsciously influence how we shape the past.
</p>

`,
//이현우
'HyeonwooLee': `
<strong>37°24'03.3"N 126°39'19.0"E, Artist Statement </strong>
<p>
내가 살고 있는 곳은 뚜렷한 목적으로 설계되어 매립된 신도시이다. 자연스레 이곳의 풍경은 도시계획에 의해 인공물과 자연물이 조화를 이루며 익숙한 풍경으로 다가온다. 하지만 종종 계획된조화에서 벗어나 이탈된 것들과 마주할때 낯설음을 느꼈다.
나는 이러한 경험이 인공과 자연의 접점 영역에서 상호 간 침투하며 야기된 경계의 모호성에 의한 것으로 판단했다. 하여 이탈된오브제와 풍경을 기록하는 동시에 인위적인 조작을 가해 가상의이탈을 재현하고자 하였다.

The place where I live in is the new city that has been planned and reclaimed with its deﬁnite goal. Naturally, the scenery of this city is familiar, as a result of the harmonization with the artifacts and organic structures, which both have been set by the city planning. However, I always felt unusual when I sometimes happened to encounter the deviated elements from the devised harmony.
I considered that this odd experience is caused by the ambiguity on the horizon, which the latter has been mutually permeated and caused by the contact point of the artiﬁciality and nature. Thus, I intended to record the scenery and deviated objet, and simultaneously, represent the virtual deviation by supplementing an artiﬁcial manipulation.
</p>

<strong>Overlayer, Artist Statement </strong>
<p>
송도신도시는 1980년대 후반 구상된 계획에 의해 바다에 매립된 도시이다. 어린시절 신도시 근방에서 자란 나는 스무 살이 되던 해 이곳으로 이사왔다. 어느날 공사가 한창인 도시 외곽에 자라난 염생식물을 발견하게 되었다. 그 식물은 이식된 흙 위에서 갯벌의 흔적을 증명하고 있었다. 그리고 어린시절 신도시 자리에 끝없이 펼쳐진 바다를 바라봤던 기억과 함께 신도시를 인간의 관념이 구현된 곳으로 바라보게 했다. 그렇게 신도시의 풍경은 가상과 현실의 층위가 혼재되어 있는 이질적인 감각으로 다가왔다. 이번 작업을 통해 이질적인 감각이 만들어 내는 환영을 사진으로 재현하고자 했다. 염생식물을 처음 발견한 장소의 2000년대 항공사진을 투명 아크릴판에 출력하였다. 그리고 이곳에서 과거에 서식하던 염생식물들을 3D 모델링으로 재현하여 식물의 흔적이 남아있는 공사현장에 아크릴판을 설치하고 빔프로젝터를 사용해 투사하였다.

The New Town of Songdo is a city buried in the sea according to a plan that was designed in the late 1980s. Growing up near the new town as a child, I moved here around the time I turned 20. One day, I found salt plants growing outside of the city where the construction was in progress. The plants were evidence of the traces of mudﬂats on the transplanted soil. This, along with the memory of looking out at the endless sea in the location of the new town as a child, it made me look at the new town as a place where human concepts have been embodied. In that way, the landscape of the new town came in a heterogeneous sense in which the layers of virtual and reality were mixed. I tried to represent the illusion created in that heterogeneous sense with photographs through this work. I also printed the aerial photograph from the 2000s where the salt plants were discovered for the ﬁrst time on a transparent acrylic panel. And here, the salt plants that used to live in the past were recreated through 3D modeling, the acrylic panel was installed at the construction site where the traces of plants remained, and the image was projected using a beam projector.
</p>
<strong>Soft echo, Artist Statement </strong>
<p>
이 작업은 스위스 대사관 건물 내부에 대사관이 위치한 서울시 송월동 일대에서 사용되었던 과거의 사물들을 가져와 촬영한 작업이다. 스위스 대사관은 ‘스위스 한옥’이라는 프로젝트로 한국의 역사에 대한 존중과 함께 이질적인 두 문화와 더불어 과거와 현재라는시간이 교차하는 곳이다. 그럼에도 이국적으로 다가온 대사관 건물의 내부는 스위스의 문화와 관념의 영향 속에서 현대식 가구와 장식품들이 정교하게 배치되었기때문이라고 생각했다. 그런 점에서나는 재정비되어 사라져 버린 지금의 건물이 위치한 돈의문 일대의과거 주민들의 생활 흔적들을 찾아 대사관 안으로 가져오고자 했다. 서울역사박물관의 아카이브 사진에서 수집한 한국 전통가옥의사물들을 배경 판과 빔프로젝터를 이용해 대사관 건축물의 내부 공간에 투사하여 재구성된 실재 공간을 촬영하였다. 촬영 시 대사관내부를 기록하는 촬영 노출시간과 빔프로젝터의 빛을 기록하는 투사 시간의 차이를 통해 사진 속 현재의 시공간에 과거의 사물들을환영처럼 드러낸다. 과거와 현재가 교차하는 순간을 통해 콘트리트벽으로 나눠지는 두 나라의 관념적 경계를 관통한다.

This work was taken with objects from the past used in Songwol-dong, Seoul, where the embassy is located inside the Swiss embassy building. The Swiss Embassy is a project called ‘Swiss Hanok’, where the time of past and present intersect with respect for Korean history and two different cultures. Nevertheless, I thought that the exotic interior of the embassy building was due to the elaborate arrangement of modern furniture and ornaments under the inﬂuence of Swiss culture and concept. In this regard, I tried to ﬁnd traces of the lives of the past residents in the Donuimun area where the present building is located and bring them into the embassy. Objects of traditional Korean houses collected from archive photos of the Seoul Museum of History were projected onto the interior space of the embassy building using a background board and a beam projector to photograph the reconstructed real space. Through the difference between the exposure time, which records the inside of the embassy, and the projection time, which records the light of the beam projector, the objects of the past are revealed in the present time and space in the photograph as an illusion. Through the moment when the past and the present intersect, it penetrates the ideological boundary between the two countries divided by a concrete wall.
</p>

`,
//이예은
'YeeunLee': `
<strong>
무모 연작, Artist Statement </strong>
<p>
티백 하나를 바다에 우려 내기도 하고 온 몸으로 건물을 껴안아 실내온도를 높이려 하기도 했다. 어리석은 행동 같이 보일 수 있지만 나는 공장에서 이런 무모를 반복하며 지내왔다. 이날이 그날 같고 그날이 저날 같다. 어제 같은 오늘과 내일 같은 어제가 반복되었다. 산더미같이 쌓인 물건들을 꼬박 하루내내 소분해서 처리해도 다음날 그들은 다시 산더미가 되어 나를 마주했다. 다행히도 내 몸은 금새 일을 하는 몸으로 맞춰져 작동했다. 쉬는 시간에는 다음 일을 하려고 쉬었고 밥을 먹을 때에는 남은 업무를 잘 처리하려고 먹었다. 집으로 돌아가서는 내일의 일을 위해 일찍 잠들었다.
내가 이런 현장을 이야기 하면 사람들은 어째서인지 지루함을 드러내곤 했다. 언제나 그대로인 탓인지 혹은 믿는 대로 보는 세상이라서 그런지 이제는 흔해 빠져 닳아 버린 것 같은 노동의 이야기는 소용없다고 생각할지도 모르겠다. 그럼에도 나는 계속해서 무모한 말을 하고싶다.
</p>
<strong>
마음 쌓기 , Artist Statement </strong>
<p>
코로나로 온 세상이 불안해하는 시기에 위로가 되고 힘이 되는 긍정적인 메시지를 타인에게 전달하고 싶었다. 산에서 볼 수 있는 돌탑은 돌마다 제 각기 다른 염원과 소원 그리고 이야기가 담겨 져 있다. 지극히 평범한 돌을 쌓는다고 특별한 능력을 지닌 돌이 되는 것은 아니지만 정성을 다해 쌓아 올린 그들의 마음에 집중하고 싶었다. 나는 주변 사람들에게 평소 힘과 위로를 받았던 평범한 물건들을 받아서 사진을 보게 될 어느 누군가에게 힘이 되기를 바라는 마음으로 위로가 되어 준 물건들을 쌓아 올리게 되었다.
</p>
<strong>
공간 쌓기, Artist Statement </strong>
<p>
산을 오르다 보면 누가 언제부터 쌓아 올렸는지 모르는 다양한 형태의 돌탑을 쉽게 만날 수 있다. 돌탑은 여러 사람들이 오고 가며 쌓아 올린 것으로 평범한 돌들은 사람들의 마음을 담아 균형을 맞추어 의미 있는 탑이 되며 산은 돌탑으로 인해 특별한 의미를 지닌 공간이 된다. 내게는 공간 안에서 관계를 맺어가는 사람들의 모습이 마치 돌탑의 모습처럼 느껴졌다. 그들은 관계를 형성하고 균형을 맞추어 관계를 이어간다. 그 모습은 그들이 머무는 공간을 그저 단순한 건물이 아닌 더 의미 있고 살아 움직이게 한다. 나는 하나의 건물 안에서 근무를 하는 사람들에게 평범할 지라도 자신이 평소에 사용하는 의미 있는 물건을 제공해 줄 것을 요청하였고 그들에게 받은 물건으로 공간 속 그들의 관계를 닮은 탑을 쌓아 올렸다.
</p>

`,
//김도영
'DoyoungKim': `
<strong>
On the Ground: Underground, Artist Statement </strong>
<p>
이 사진들은 사진과 공간이 프레임을 매개로 나누어지는 익숙한 사진의 구조에서 벗어나 ‘한 공간이 담긴 사진과 다른 공간’의 공존이 만들어내는 새로운 사진의 공간과 물성에 대한 시도이다. 본 작업은 지상의 바닥을 기준으로 나누어진 공간의 구조를 실재와 이미지가 결합된 새로운 형태의 공간으로 만들어 나간다. 스위스 대사관의 공간은 땅을 기준으로 그 특성이 급격히 변화한다. 지하와 지상은 공간을 형성하는 벽과 바닥, 그곳에 놓인 시설까지 모두 상반된 물성을 갖고 있다. (지상의 매끄럽고 부드러운 정돈된 질감과 달리 지하는 어둡고 거칠며 다듬어지지 않았 다.) 지상 층은 거주지와 일터로서 기능하고 지하는 건물의 유지 보수를 위한 시설이 밀집된다. 기능은 장소의 쓸모를 구분 짓고 각각의 분위기를 만들어 간다. 나는 땅 밑에 매복 되어 통제되는 지하에 집중하며 위치적, 기능적으로 구분된, 이분법적 구조를 변형시키고 쉽게 보이지 않는 지하의 공간을 사진을 통해 지상의 공간으로 끌어 올려서 결합된 형태의 새 공간을 시도하였다.

These photographs explore the physical boundaries of photography by breaking away from conventional arrangements, where the exhibition space merely accommodates for a series of frames. Instead, there is a sense of interaction between the spaces which the photographs portray and the space in which they are displayed. By virtue of the spatial division between underground and overground, reality and representation start to blend together. Moreover, the building of the Swiss Embassy itself undergoes a spatial metamorphosis. The walls, the floors and even the facilities, jointly providing spaces above ground as well as below, show contrasting physical properties. We generally find things above ground to be smooth, gentle and polished, whereas underground things are dark, rough and unrefined. We live and work on ground level, whilst the basement is used to store maintenance equipment. Each place derives its use from its function, conjuring up a distinctive atmosphere. This newly created space focuses on the subdued, restrained nature of underground spaces, transforming the spatial and functional dichotomy with all that is overground. In doing so, this exhibition brings the underground spaces up to ground level and merges them into one.
</p>

<strong>Flat Room, Artist Statement </strong>
<p>
작업 ‘평평한 방(2022)’ 는 사진의 평면성을 실재 공간으로 확장하기 위한 시도이다.작업 ‘평평한 방(2022)’ 은 사진에 찍힌 장소가 곧 사진이 있는 장소이며, 사진에 찍힌 피사체가 곧 지금 눈 앞에 있는 대상이다. 사진과 실재 공간을 연결하고 있는 대상은 사진의 안과 밖에 함께 위치하며, 사진 속 공간과 현실의 공간을 연결하고 단절한다. 작업 ‘평평한 방(2022)’ 은 실재 공간과 대상/ 이미지 속 공간과 대상을 연결하는 동시에 단절하며 사진의 평면성에 대한 확장을 시도한다.

The work ‘Flat Room (2022)’ which is produced in the exhibition space, is an attempt to expand the flatness of the photography into the real space. In the work, the place where the photo was taken, is the place where the photo is. And the object in the photo is the same object which is placed out of the photo. The objects, that lead inside and outside of the photos, expand the flatness of the photography. Through This Work I tried to combine and conflict the real place with place in the Image at the same time.
</p>

<strong>80g, Artist Statement </strong>
<p>
작업 ‘80g(2022)’ 은 대상과 그것이 기록된 종이의 관계를 새롭게 바라보기 위한 시도이다. 입체의 대상을 사진을 통해 기록할 때 사진은 대상의 고유한 물성을 제거하며, 시각적 정보만을 단편적으로 전달한다. 이 과정에서 전달하는 매개체인 종이는 이미지 아래로 자신을 감춘다. 모든 사진들에 공통적으로 사용된 80g 무게의 종이 - 평면성을 넘어서는- 를 통해 나는 이미지 이면에 감추어진 종이의 물성을 적극적으로 드러내고자 한다.
작업은 현대 도시 건축에 사용되는 자재들을 사진의 대상으로 한다. 도시와 건축의 기본 목적은 “무너지지 않기” 이다. 이를 위해 사용되는 차갑고 견고한 재료들은 중력을 거스르며 무거운 무게를 지탱하기 위해 선택된다. 콘크리트, 철근, 알루미늄, 강철등 모든 선택된 재료들은 고정된 형태없이 그것이 지닌 물성의 기능을 통해 활용된다.
‘80g’ 을 통해 나는 프린트된 사진을 다양한 방식으로 설치 촬영하며 새로운 종이를 통해 대상에 새로운 물성을 부여한다. 작업에서 보여지는 프린트되지 못한 종이의 뒷면과 주름들, 무게를 이기지 못해 늘어진 형태는 그곳에 기록된 견고함과 함께 이미지 뒤편의 가벼움을 보여준다.

‘80g (2022)’ is the work attempts to look a new relationship between the object and the paper where it is captured. In this work I concentrate on the material that makes space and city When capturing object through photography the photograph delivers only visual information excluding most of Characteristic of the object. In this process, paper, as a medium for delivering visual information, hide themselves under the image. I actively reveal the physical properties of the paper hidden under the image through the work ‘80g’.
The work borrows images of materials used in cities and architecture. The basic purpose of cities and architecture is “not to collapse”. The materials used for this are chosen to withstand gravity and to support the heavy weight. Concrete, rebar, aluminum, steel, Etc...
It functions through its physical properties not with certain fixed form. I installed printed photos in various ways and take picture of them and make a new formability using the characteristics of paper. The back of the printed paper, the unprinted part, wrinkles, and the shape of the paper hangs down because it cannot withstand the weight. They show the hardness and the lightness(80g) at the same time.
</p>

`,

// 다른 작가들...
};
