import { createApp } from 'vue'; 
import App from './App.vue';
import router from './router';
import './assets/global.css';
import VueLazyLoad from 'vue-lazyload';
import EasyLightbox from 'vue-easy-lightbox';

const app = createApp(App);

// VueLazyLoad 사용
app.use(VueLazyLoad);

// VueEasyLightbox 전역 컴포넌트 등록
app.component('EasyLightbox', EasyLightbox);

app.use(router);

app.mount('#app');

