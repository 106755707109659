// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import MainView from '@/views/MainView.vue';
import PublicView from '@/views/PublicView.vue';
import AboutView from '@/views/AboutView.vue';
import ProjectView from '@/views/ProjectView.vue';
import ArtistProfileView from '@/views/ArtistProfileView.vue'
import ArtistDetailView from '@/views/ArtistDetailView.vue'; // 추가



// import ThumbnailComp from '@/views/ThumbnailComp.vue'
const routes = [
  {
    path: '/',
    name: 'MainView',
    component: MainView
  },
  {
    path: '/about',
    name: 'AboutView',
    component: AboutView
  },
  {
    path: '/publication',
    name: 'PublicView',
    component: PublicView
  },
  {
    path: '/project',
    name: 'ProjectView',
    component: ProjectView
  },
  {
    path: '/artists/:artistId',
    name: 'ArtistProfileView',
    component: ArtistProfileView,
    props: true,
    children: [
      {
        path: ':workId',
        name: 'ArtistDetailView',
        component: ArtistDetailView,
        props: true
      }
    ]
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;

